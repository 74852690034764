import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { InputEditDataType } from "popups/rename-model/rename-popup";
import { DateUtils } from "utils/dateutils";
import { Button, message, Tooltip } from "antd";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import TaskDetailCard from "components/task-detail-card";
import { ProjectContext } from "context/ProjectProvider";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { matchUserProjectRole, getDaysText } from "utils/utils";
import TimeEarlyStartIcon from "components/svg-icons/time-early-start";
import EarlyStartModelConfirm from "popups/time-early-start-model";
import { Moment } from "moment";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { MUTATION_UPDATE_PROJECTED_DATE } from "services/graphQL/mutations";
import { EUserTypes, ErrorMessages } from "../../constants";
import ProgressIndicatorCircle from "./progress-indicator-circle";
import {
  DateBlockInfoAction,
  DateBlockInfoType,
  GoverningTaskType,
  TDateBlockType,
  TEnableActualReleaseDate,
  TMileStone,
  TProjectTemplateMilestones
} from "./models";
import SetActualDatePicker from "./set-actual-date-button";

function getDateToShowDelay(block: TMileStone) {
  return block.projected || block.planned;
}

function OffsetBlock(props: {
  setIsRenameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRenameDataInput: React.Dispatch<React.SetStateAction<InputEditDataType>>;
  block: TMileStone;
  nextBlock: TMileStone | null;
  isPermissionNotGrantedForOffset: boolean;
  isSubmittalWorkflowStarted: boolean;
}) {
  const {
    setIsRenameModalOpen,
    setRenameDataInput,
    block,
    nextBlock,
    isPermissionNotGrantedForOffset,
    isSubmittalWorkflowStarted
  } = props;
  return (
    <div className="bl-rect z-10">
      <div className="duration-box bl-above-text-div">
        <div>{block.name_offset}</div>
        <div className="duration-value">
          ({block.offset} {getDaysText(block.offset)})
          {!isPermissionNotGrantedForOffset && !nextBlock?.actual && (
            <EditOutlined
              className="pl-1"
              onClick={() => {
                setRenameDataInput({
                  value: block.offset,
                  id: block.offsetID,
                  lable: block.name_offset,
                  type: "text",
                  warningMessage: isSubmittalWorkflowStarted
                    ? "Editing this duration will change the planned dates of the subsequent milestones. Are you sure you want to proceed?"
                    : undefined
                });
                setIsRenameModalOpen(true);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type Props = {
  isFirstCard: boolean;
  isLastCard: boolean;
  isSubmittal: boolean; // is Submittal DateBlock or Material DateBlock
  block: TMileStone;
  nextBlock: TMileStone | null;
  previousBlock: TMileStone | null;
  dateblock: TDateBlockType;
  setIsRenameModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRenameDataInput: React.Dispatch<React.SetStateAction<InputEditDataType>>;
  editDateBlock: (set: any) => Promise<void>;
  governingTask?: GoverningTaskType;
  enableActualReleaseDate?: TEnableActualReleaseDate;
  isSubmittalWorkflowStarted: boolean;
  componentLocation?: "MaterialDetail" | "SubmittalDetail" | "links";
  additionalMaterialOffset?: boolean;
  projectTemplateMilestones: Array<TProjectTemplateMilestones>;
  dateBlockInfoFeatureData:
    | undefined
    | { project_feature_configurations: Array<DateBlockInfoType> };
  lastIncompleteMilestone: TMileStone | null | undefined;
  isPermissionNotGrantedForOffset: boolean;
  cannotChangeActualDate: boolean;
  cannotChangeProjectedDate: boolean;
};

function DateBlockCard(props: Props) {
  const {
    isFirstCard = false,
    isLastCard = false,
    block,
    nextBlock,
    previousBlock,
    isSubmittal,
    dateblock,
    setIsRenameModalOpen,
    setRenameDataInput,
    editDateBlock,
    governingTask,
    enableActualReleaseDate,
    isSubmittalWorkflowStarted,
    componentLocation,
    additionalMaterialOffset = true,
    projectTemplateMilestones = [],
    dateBlockInfoFeatureData,
    lastIncompleteMilestone,
    isPermissionNotGrantedForOffset,
    cannotChangeActualDate,
    cannotChangeProjectedDate
  } = props;

  const { tokenContents, gqlClientForProject } = useContext(ProjectContext);

  const [updateProjectedDate] = useCIQMutation(MUTATION_UPDATE_PROJECTED_DATE, {
    client: gqlClientForProject
  });

  const isCurrentUserGC = matchUserProjectRole(
    EUserTypes.GENERAL_CONTRACTOR,
    tokenContents?.role
  );

  const [dbInfoMessages, setDbInfoMessages] = useState({
    submittal: new Array<DateBlockInfoAction>()
  });

  useEffect(() => {
    if (dateBlockInfoFeatureData) {
      const submittal =
        dateBlockInfoFeatureData.project_feature_configurations.find(
          (x) => x.feature_id === 1
        )?.date_block_action || [];

      setDbInfoMessages({ submittal });
    }
  }, [dateBlockInfoFeatureData]);

  const isCurrentMiletostone = useMemo(() => {
    if (
      isCurrentUserGC &&
      ((isSubmittal && componentLocation === "SubmittalDetail") ||
        (!isSubmittal && componentLocation === "MaterialDetail"))
    ) {
      if (lastIncompleteMilestone === null && block.sequence_no === 1)
        return true;
      if (block.sequence_no === lastIncompleteMilestone?.sequence_no)
        return true;
    }
    return false;
  }, [
    isCurrentUserGC,
    isSubmittal,
    componentLocation,
    block.sequence_no,
    lastIncompleteMilestone
  ]);

  const updateFeatureProjectedDate = async (mapObj: any) => {
    const payload: any = {
      variables: {
        feature_instance_id: isSubmittal
          ? dateblock?.submittal_id ?? ""
          : dateblock?.material_id ?? "",
        feature_type: isSubmittal ? "SUBMITTAL" : "MATERIAL",
        projected_date_key: mapObj?.projected_date_key,
        projected_date_value: mapObj?.projected_date_value
      }
    };

    const response: any = await updateProjectedDate(payload);

    if (response?.success) {
      message.success("Updated successfully.");
    }
  };

  const disabledProjectedDate = (current: Moment) => {
    const currDateFromPicker = DateUtils.dateTimeObj(current)
      .hours(13)
      .minutes(0)
      .seconds(0);
    const before =
      currDateFromPicker.isBefore(
        DateUtils.dateTimeObj(previousBlock?.actual)
      ) || currDateFromPicker.isBefore(DateUtils.dateTimeObj());
    return before;
  };

  const isCurrentRunningMilestore = useMemo(() => {
    if (
      ((isSubmittal && componentLocation === "SubmittalDetail") ||
        (!isSubmittal && componentLocation === "MaterialDetail")) &&
      block.sequence_no === lastIncompleteMilestone?.sequence_no &&
      (isFirstCard || previousBlock?.actual)
    ) {
      return true;
    }
    return false;
  }, [
    block.sequence_no,
    componentLocation,
    isFirstCard,
    isSubmittal,
    lastIncompleteMilestone?.sequence_no,
    previousBlock?.actual
  ]);

  const projectedDiv = !block.actual && block.projected && (
    <div className="flex-1 pt-2">
      <div className="flex items-center">
        <span className="font-semibold mr-1">Projected date:</span>
        {!isFirstCard && isCurrentMiletostone && (
          <DatePickerWithIcon
            value={
              block?.projected
                ? DateUtils.dateTimeObj(block?.projected)
                : undefined
            }
            disabled={cannotChangeProjectedDate}
            disabledDate={disabledProjectedDate}
            onChange={(date) => {
              if (date) {
                const saveDate = date
                  ? DateUtils.formatDateWithLunchTime(date)
                  : null;
                const updates = {
                  projected_date_key: block.projectedId,
                  projected_date_value: saveDate
                };
                updateFeatureProjectedDate(updates);
              }
            }}
          />
        )}
      </div>
      <span
        className={
          dateblock.current_delay != null &&
          dateblock.current_delay > 0 &&
          !isCurrentRunningMilestore
            ? "bg-delay pl-1 pr-2 py-[1px] rounded italic"
            : ""
        }
      >
        {DateUtils.format(block.projected)}
      </span>
    </div>
  );

  const plannedDiv = (
    <div className="flex-1">
      <div className="space-x-1 flex items-center">
        <span className="grow font-semibold">Planned date:</span>
        <span className="w-[20px] h-[20px]" />
      </div>
      <span>{block.planned ? DateUtils.format(block.planned) : "-"}</span>
    </div>
  );
  const childRefActualDate = useRef<any>();
  const [isOpenEarlyStartModel, setIsOpenEarlyStartModel] = useState(false);

  const actualDivSubmittal = isSubmittal && block.actual && (
    <div className="flex-1 pt-2 flex justify-between">
      <div>
        <div className="space-x-1 flex items-center">
          <span className="grow font-semibold">Actual date:</span>
        </div>
        <span>{DateUtils.format(block.actual)}</span>
      </div>
    </div>
  );

  const disabledActualDate = (current: Moment) => {
    const after = DateUtils.dateTimeObj(current).isAfter(
      DateUtils.dateTimeObj()
    );
    if (!previousBlock) return after;

    const currDateFromPicker = DateUtils.dateTimeObj(current)
      .hours(13)
      .minutes(0)
      .seconds(0);
    const before = currDateFromPicker.isBefore(
      DateUtils.dateTimeObj(previousBlock?.actual)
    );
    return before || after;
  };

  const accessMaterialActualDate = useMemo(() => {
    const access = {
      canViewActualDate: false,
      canViewDatePickerEdit: false,
      canViewEarlyDate: false,
      canEditEarlyDate: false
    };

    const pageOfEditable = componentLocation === "MaterialDetail";

    ///
    // if actual date then user can view actual date
    // if user has edit access then first milestone they can view and edit
    // if next milestone has actual value then we are not allow to edit
    // Material can edit only on Material Detail page
    // Implicit material can edit only on Linking page ( Submittal Linking )
    // on First Milestone, if any linked submittal are still open then Date picker will hide, And Early Date date icon will visible
    // If user submit actual date using early date and after few days ago if all submittal are closed then stil we need to show early start
    ///

    if (
      block.actual ||
      (pageOfEditable &&
        !cannotChangeActualDate &&
        (previousBlock?.actual || isFirstCard))
    ) {
      access.canViewActualDate = true;
    } else {
      return access;
    }

    if (!(cannotChangeActualDate || nextBlock?.actual || !pageOfEditable)) {
      if (
        isFirstCard &&
        enableActualReleaseDate?.enable &&
        !dateblock.wf_override_reason
      ) {
        access.canViewDatePickerEdit = true;
      } else if (previousBlock?.actual) {
        access.canViewDatePickerEdit = true;
      }
    }

    if (isFirstCard && !access.canViewDatePickerEdit) {
      if (pageOfEditable && !cannotChangeActualDate && !nextBlock?.actual) {
        access.canEditEarlyDate = true;
      }
      if (access.canEditEarlyDate || dateblock.wf_override_reason) {
        access.canViewEarlyDate = true;
      }
    }

    return access;
  }, [
    block.actual,
    cannotChangeActualDate,
    componentLocation,
    dateblock.wf_override_reason,
    enableActualReleaseDate?.enable,
    isFirstCard,
    nextBlock?.actual,
    previousBlock?.actual
  ]);

  const materialReleasedEarlyMsg = useMemo(() => {
    return (
      <div className="p-2">
        <div className="mb-1.5">Material Released Early.</div>
        <div>
          <span className="font-semibold">Reason Cited:</span>{" "}
          <span>{dateblock.wf_override_reason}</span>
        </div>
      </div>
    );
  }, [dateblock.wf_override_reason]);

  const dash = block.projected ? "" : "-";

  const onActualDateChange = (date: Moment | null) => {
    if (date) {
      const updates = {} as any;
      const saveDate = date ? DateUtils.formatDateWithLunchTime(date) : null;
      updates[`${block.actualId}`] = saveDate;
      editDateBlock(updates);
    }
  };

  const actualDivMaterial =
    !isSubmittal &&
    accessMaterialActualDate.canViewActualDate &&
    (isCurrentMiletostone && !accessMaterialActualDate.canViewEarlyDate ? (
      <div className="absolute bottom-2 right-3 flex items-center justify-center">
        <SetActualDatePicker
          onChange={onActualDateChange}
          disabledDate={disabledActualDate}
        />
      </div>
    ) : (
      <div className="flex-1 pt-2 flex justify-between">
        <div>
          <div className="space-x-1 flex items-center">
            <div className="grow font-semibold">Actual date:</div>

            <div className="flex items-center">
              {accessMaterialActualDate.canViewDatePickerEdit && (
                <DatePickerWithIcon
                  ref={childRefActualDate}
                  value={
                    block.actual
                      ? DateUtils.dateTimeObj(block.actual)
                      : undefined
                  }
                  onChange={onActualDateChange}
                  disabledDate={disabledActualDate}
                  renderExtraFooter={() => {
                    if (!block.actual) return undefined;
                    return (
                      <div className="flex justify-center">
                        <Button
                          onClick={() => {
                            childRefActualDate.current?.close();

                            const updates = {} as any;
                            updates[`${block.actualId}`] = null;
                            if (isFirstCard && dateblock.wf_override_reason)
                              updates.wf_override_reason = null;
                            editDateBlock(updates);
                          }}
                        >
                          Clear Actual Date
                        </Button>
                      </div>
                    );
                  }}
                />
              )}

              {accessMaterialActualDate.canViewEarlyDate && (
                <Tooltip
                  title={
                    dateblock.wf_override_reason
                      ? materialReleasedEarlyMsg
                      : ErrorMessages.EarlyStartMsg
                  }
                >
                  <div className="min-w-0 pl-1 items-center flex">
                    <TimeEarlyStartIcon
                      className={
                        accessMaterialActualDate.canEditEarlyDate
                          ? "hover:opacity-100 opacity-80 cursor-pointer"
                          : "opacity-50 cursor-not-allowed"
                      }
                      onClick={() => {
                        if (accessMaterialActualDate.canEditEarlyDate)
                          setIsOpenEarlyStartModel(true);
                      }}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>

          <span>{block.actual ? DateUtils.format(block.actual) : dash}</span>
        </div>
      </div>
    ));

  const cssClassForConnectingLine = useMemo(() => {
    if (isSubmittal) return "w-20 h-0 relative flex justify-end";
    if (isLastCard && !additionalMaterialOffset)
      return "w-20 h-0 relative flex justify-end";
    return "w-20 h-0 relative flex justify-end border-0 border-t border-solid";
  }, [additionalMaterialOffset, isLastCard, isSubmittal]);

  const floatDiv = isLastCard && (
    <div className="flex items-center">
      <div className="w-40 border-0 border-t border-solid relative shrink-0" />
      <div className="w-12 h-4 border border-solid relative shrink-0 border-gray-500 bg-gray-200">
        <div className="duration-box bl-above-text-div">
          <div>
            {isSubmittal
              ? "Submittal Float & Deadline"
              : "Material Float & Deadlines"}
          </div>
        </div>
      </div>
      <div className={cssClassForConnectingLine} />
    </div>
  );

  const governingTaskDiv = governingTask?.id ? (
    <Tooltip
      className="govering-db-toolitip"
      overlayStyle={{ maxWidth: "300px" }}
      title={
        <TaskDetailCard
          taskId={governingTask.id}
          isLinkedEndDate={governingTask.isLinkedEndDate}
        />
      }
      placement="top"
    >
      <div className="h-6 w-full border border-solid  border-gray-400 flex items-center px-1 cursor-pointer text-xs">
        <b className="mt-1 mr-1">*</b>{" "}
        <div className="grow truncate">{governingTask.text || ""}</div>
      </div>
    </Tooltip>
  ) : (
    ""
  );

  const isPlannedDateIsDelay = useMemo(() => {
    return (
      DateUtils.dateTimeObj()
        .startOf("D")
        .diff(
          DateUtils.dateTimeObj(getDateToShowDelay(block)).startOf("D"),
          "d"
        ) > 0
    );
  }, [block]);

  const computedOffsetBlock = useMemo(() => {
    const offsetBlk = (
      <OffsetBlock
        setIsRenameModalOpen={setIsRenameModalOpen}
        setRenameDataInput={setRenameDataInput}
        block={block}
        nextBlock={nextBlock}
        isPermissionNotGrantedForOffset={isPermissionNotGrantedForOffset}
        isSubmittalWorkflowStarted={
          isSubmittal
            ? isSubmittalWorkflowStarted
            : enableActualReleaseDate?.isAnySubmittalStarted!
        }
      />
    );
    if (isSubmittal) {
      if (isLastCard) return null;
      return offsetBlk;
    }
    // isMaterial
    if (isLastCard && !additionalMaterialOffset) return null;
    return offsetBlk;
  }, [
    block,
    enableActualReleaseDate?.isAnySubmittalStarted,
    additionalMaterialOffset,
    isLastCard,
    isPermissionNotGrantedForOffset,
    isSubmittal,
    isSubmittalWorkflowStarted,
    nextBlock,
    setIsRenameModalOpen,
    setRenameDataInput
  ]);

  const currentDelayDiv = (
    <div className="h-12 w-[148px]">
      {dateblock.can_show_current_delay &&
        dateblock.current_delay != null &&
        dateblock.current_delay > 0 &&
        isCurrentRunningMilestore && (
          <div className="bg-delay text-xs p-1 flex items-center rounded-b">
            <div className="grow">
              <div className="flex items-center justify-center lowercase">
                <span className="font-bold pr-1">
                  {dateblock.current_delay}{" "}
                  {getDaysText(dateblock.current_delay)}
                </span>
                <span>late</span>
              </div>
            </div>
            <Tooltip
              title={`As of today, ${DateUtils.format(dateblock.today_date)}.`}
              overlayInnerStyle={{ color: "#000000CC" }}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}
      {isLastCard && governingTaskDiv}
    </div>
  );

  return (
    <div className="flex-col pr-5">
      <div className="flex h-8">
        <div className="absolute">
          <div className="relative flex items-center">
            {isFirstCard ? (
              <div className="w-16" />
            ) : (
              <div className="bl-line-left" />
            )}
            <ProgressIndicatorCircle
              actualDate={block.actual}
              projetedDate={block.projected}
            />
            {floatDiv}
            {!isLastCard && <div className="bl-line" />}
            {computedOffsetBlock}
          </div>
        </div>
      </div>
      <div
        className={
          // eslint-disable-next-line no-nested-ternary
          isCurrentRunningMilestore
            ? isPlannedDateIsDelay
              ? "relative bg-white flex-col date-block-size border border-solid next-milestone-delay-border"
              : "relative bg-white flex-col date-block-size border border-solid next-milestone-on-track-border"
            : "relative bg-white flex-col date-block-size border border-solid"
        }
      >
        <div
          className={
            // eslint-disable-next-line no-nested-ternary
            isCurrentRunningMilestore
              ? isPlannedDateIsDelay
                ? "card-header next-milestone-delay-text"
                : "card-header next-milestone-on-track-text"
              : "card-header"
          }
        >
          <div className="line-clamp2" title={block.name_milestone}>
            {block.name_milestone}
          </div>
          {isCurrentMiletostone && (
            <Tooltip
              title={
                <div className="p-1">
                  {isSubmittal
                    ? dbInfoMessages.submittal.find(
                        (x) => x.step === block.plannedID
                      )?.action || ""
                    : projectTemplateMilestones.find(
                        (x) => x.sequence_no === block.sequence_no
                      )?.milestone_action || ""}
                </div>
              }
            >
              <InfoCircleOutlined className="pl-1" />
            </Tooltip>
          )}
        </div>
        <div className="p-2 box-content">
          <div className="text-xs h-full ">
            {plannedDiv}
            {projectedDiv}
            {actualDivSubmittal || null}
            {actualDivMaterial || null}
          </div>
        </div>
      </div>
      {currentDelayDiv}
      {isOpenEarlyStartModel && (
        <EarlyStartModelConfirm
          isModalOpen={isOpenEarlyStartModel}
          setIsModalOpen={setIsOpenEarlyStartModel}
          milestoneName={block.name_milestone}
          existingActualDate={block.actual}
          existingReason={dateblock.wf_override_reason || null}
          onOk={(prop) => {
            const updates = { wf_override_reason: prop.reason } as any;
            const saveDate = prop.actualDate
              ? DateUtils.formatDateWithLunchTime(prop.actualDate)
              : null;
            updates[`${block.actualId}`] = saveDate;

            return editDateBlock(updates);
          }}
          disabledDate={disabledActualDate}
        />
      )}
    </div>
  );
}
export default DateBlockCard;
