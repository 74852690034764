import { Form, FormRule, message, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { DateBlockInfoAction } from "components/date-block/models";
import RenameFieldSettings from "components/feature-rename-fields-settings";
import {
  ThresholdType,
  WorkflowDefaultData
} from "pages/project-setting-menu/model";
import { MUTATION_PROJECT_DEFAULT_CONFIG } from "services/graphQL/mutations";
import { useMutation } from "@apollo/client";
import { ProjectContext } from "context/ProjectProvider";
import FeatureTemplateProjectSettings from "components/manage-feature-wf-templates/feature-template-project-settings";
import { FeatureTypes } from "entity-app/constants";

const designPackageHeaderTitleMap: any = {
  id: "ID",
  number: "Number",
  title: "Title",
  description: "Description",
  responsible_designer_id: "Responsible Design Firm",
  design_firm_id: "Responsible Designer",
  workflow_status: "Status",
  next_deadline: "Next Deadline",
  due_date: "Due Date",
  risk: "Risk",
  float: "Float",
  workflow_template_id: "Workflow Template ID",
  milestones: "Milestones"
};

function DesignPackageSettings(props: {
  designPackageDateBlockActions: Array<DateBlockInfoAction>;
  featureConfigurationsData: any;
  newProjectOffsetData: any;
  designPackageHeader: any;
  disabled: boolean;
}) {
  const {
    designPackageDateBlockActions,
    featureConfigurationsData,
    newProjectOffsetData,
    designPackageHeader,
    disabled
  } = props;

  const { gqlClientForProject } = useContext(ProjectContext);

  const [form] = Form.useForm();
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [projectOffset, setProjectOffset] = useState<any>();
  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const history = useHistory();
  const { projectId, tab3Id } = useParams() as any;

  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "templates");

  // const [designPackageOffsets, setDesignPackageOffsets] =
  //   useState<Array<WorkflowDefaultData>>();

  const formRule: FormRule[] = [
    {
      validator: async (_, value) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(value)) {
          setShowWarningMsg(true);
          return Promise.reject(
            new Error("Please enter duration in numbers only.")
          );
        }
        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];

  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: "Please enter threshold"
    },
    {
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject(
            new Error("Please enter threshold in numbers ( + or - ) only.")
          );
        }
        return Promise.resolve();
      }
    },
    {
      warningOnly: true,
      message: (
        <div className="text-[#3B3B3BCC]">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      validateTrigger: "onSubmit",
      message:
        "High risk threshold has to be lower than the low risk threshold.",
      validator: () => {
        const threshold: ThresholdType =
          form.getFieldValue("materialThreshold");
        if (
          Number(threshold.low_risk_threshold) <=
          Number(threshold.high_risk_threshold)
        ) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const renameFieldRules: FormRule[] = [
    {
      required: true,
      message: "Please enter field name",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter field name"));
        }
        return Promise.resolve();
      }
    }
  ];

  const milestoneActionsRules: FormRule[] = [
    {
      required: true,
      message: "Please enter milestone action",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter milestone action"));
        }
        return Promise.resolve();
      }
    }
  ];

  const settingsFormRules: { [key: string]: FormRule[] } = {
    defaultoffset: formRule,
    thresholdRules,
    renameFieldRules,
    milestoneActionsRules
  };

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  useEffect(() => {
    if (
      newProjectOffsetData &&
      newProjectOffsetData.workflow_defaults &&
      featureConfigurationsData &&
      featureConfigurationsData.project_feature_configurations
    ) {
      const defaultsMap: any = {};
      featureConfigurationsData.project_feature_configurations.forEach(
        (feature: any) => {
          if (feature?.feature_id === 5)
            defaultsMap.designPackageThreshold = feature;
        }
      );
      newProjectOffsetData.workflow_defaults?.forEach((wf_offset: any) => {
        if (wf_offset.feature_id === 4) {
          const designPackageOffsetsArr: Array<WorkflowDefaultData> =
            wf_offset.workflow_data ? [...wf_offset.workflow_data] : [];

          designPackageOffsetsArr.push({
            offset_name: "design_package_float",
            offset_value: wf_offset.float,
            milestone_name: "Design Package Float"
          });
          // setDesignPackageOffsets(designPackageOffsetsArr);
          designPackageOffsetsArr.forEach(
            (design_package_offset: WorkflowDefaultData) => {
              const key = design_package_offset.offset_name.replaceAll(
                " ",
                "_"
              );
              defaultsMap[key] = design_package_offset.offset_value;
            }
          );
        }

        defaultsMap.field_mapping = designPackageHeader?.field_mapping || [];
        defaultsMap.actions = designPackageDateBlockActions;
        form.setFieldsValue(defaultsMap);
        setProjectOffset(defaultsMap);
      });
    }
  }, [
    featureConfigurationsData,
    form,
    designPackageHeader,
    newProjectOffsetData,
    designPackageDateBlockActions
  ]);

  const tabItems = [
    {
      label: "Design Package Templates",
      key: "templates",
      children: (
        <FeatureTemplateProjectSettings
          featureId={FeatureTypes.DESIGN_PACKAGE}
        />
      )
    },
    {
      label: "Rename Fields",
      key: "rename-fields",
      children: (
        <RenameFieldSettings
          isFieldsEditable={isFieldsEditable}
          submitInProgress={submitInProgress}
          showWarningMsg={showWarningMsg}
          cancelAction={cancelAction}
          disabled={disabled}
          setFieldsEditable={setFieldsEditable}
          projectOffset={projectOffset}
          header={designPackageHeader}
          headerTitleMap={designPackageHeaderTitleMap}
          settingsFormRules={settingsFormRules}
          page="Design Package"
        />
      )
    }
  ];

  const [updateProjectConfigMutation] = useMutation<any>(
    MUTATION_PROJECT_DEFAULT_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  const updateRenameFields = (data: any) => {
    const variables = {
      feature_id: designPackageHeader?.feature_id,
      field_mapping: data?.field_mapping,
      date_block_action: null
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const onFinishForm = async (data: any) => {
    let updateResponse: any;

    setSubmitInProgress(true);
    if (selectedTab === "rename-fields") {
      updateResponse = await updateRenameFields(data);
    }

    if (!updateResponse) {
      setSubmitInProgress(false);
      return;
    }

    Promise.all([updateResponse]).then((res: [any]) => {
      const errorMes = res.find((r) => r.errors && r.errors[0]?.message);
      if (errorMes) {
        message.error(errorMes.errors[0]?.message);
      } else {
        message.success("Default Settings are updated successfully.");
      }
      setFieldsEditable(false);
      setSubmitInProgress(false);
    });
  };

  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <Form
        layout="vertical"
        form={form}
        initialValues={projectOffset}
        onFinish={onFinishForm}
        className="flex flex-col"
        disabled={disabled}
        validateTrigger={["onSubmit", "onChange"]}
      >
        <Tabs
          tabPosition="left"
          tabBarGutter={2}
          tabBarStyle={{ width: "180px" }}
          activeKey={selectedTab}
          items={tabItems}
          onChange={(activeKey) => {
            history.push(
              `/project/${projectId}/settings/modules/design-packages/${activeKey.toString()}`
            );
            setSelectedTab(activeKey);
          }}
          destroyInactiveTabPane
        />
      </Form>
    </div>
  );
}

export default DesignPackageSettings;
