import { useContext, useEffect, useMemo, useState } from "react";
import { message, Spin } from "antd";
import { MUTATION_SEND_FEATURE_WF_INVITE } from "services/graphQL/mutations";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import { ProjectContext } from "context/ProjectProvider";
import ErrorBoundary from "components/error-boundary";
import {
  IMaterial,
  IMaterialGroup,
  IMaterialGroupFormFields
} from "../type-definitions";
import useRequestDurationsMulti from "../hooks/request-durations-multi";
import NoAssigneeRhsContent from "./no-assignee-rhs-content";
import HasAssigneeRhsContent from "./has-assignee-rhs-content";
import { VerticalList, VerticalListItem } from "../widgets";
import Footer, { SendRequestsBtnDisabledReasons } from "./footer";
import "../request-durations.scss";

function getGroupById(groupId: string, groupedMaterials: any) {
  if (groupedMaterials?.unassignedGroup?.groupId === groupId)
    return groupedMaterials?.unassignedGroup;
  if (groupedMaterials?.unregisteredGroup?.groupId === groupId)
    return groupedMaterials?.unregisteredGroup;
  if (groupedMaterials?.inactiveUserGroup?.groupId === groupId)
    return groupedMaterials?.inactiveUserGroup;
  return groupedMaterials?.assignedGroups?.find(
    (group: IMaterialGroup) => group.groupId === groupId
  );
}

function RequestDurationsMulti(props: {
  selectedItems: IMaterial[];
  onCancel: () => void;
  onComplete: () => void;
}) {
  const { selectedItems, onCancel, onComplete } = props;

  const { data, loading } = useRequestDurationsMulti(selectedItems);

  const { gqlClientForProject } = useContext(ProjectContext);

  const [sendFeatureWFInvite] = useCIQMutation(
    MUTATION_SEND_FEATURE_WF_INVITE,
    {
      client: gqlClientForProject
    }
  );

  const [selectedTabKey, setSelectedTabKey] = useState<any>(null);

  const [groupFormFields, setGroupFormFields] =
    useState<IMaterialGroupFormFields>({});

  const [submitting, setSubmitting] = useState(false);

  const unassignedUserGroups = data?.groupedMaterials?.unassignedGroup;
  const unregisteredUserGroups = data?.groupedMaterials?.unregisteredGroup;
  const assignedUserGroups = data?.groupedMaterials?.assignedGroups;
  const inactiveUserGroups = data?.groupedMaterials?.inactiveUserGroup;

  const emailsToBeSentCount = useMemo(() => {
    let totalCount = 0;
    assignedUserGroups?.forEach((group) => {
      totalCount +=
        group.materialsByStatus.allowedToSendRequestItems.length ||
        group.materialsByStatus.requestAlreadySentItems.length
          ? 1
          : 0;
    });
    return totalCount;
  }, [assignedUserGroups]);

  const excludedMaterials = useMemo(() => {
    const exMaterials: IMaterial[] = [];

    assignedUserGroups?.forEach((group) => {
      exMaterials.push(...[...group.materialsByStatus.WFStartedItems]);
    });

    exMaterials.push(
      ...(unassignedUserGroups?.materialsByStatus.allowedToSendRequestItems ??
        [])
    );

    exMaterials.push(
      ...(unregisteredUserGroups?.materialsByStatus.allowedToSendRequestItems ??
        [])
    );

    exMaterials.push(
      ...(inactiveUserGroups?.materialsByStatus.inactiveUserItems ?? [])
    );

    return exMaterials;
  }, [
    assignedUserGroups,
    unassignedUserGroups,
    unregisteredUserGroups,
    inactiveUserGroups
  ]);

  useEffect(() => {
    assignedUserGroups?.forEach((group) => {
      setGroupFormFields((prev) => ({
        ...prev,
        [group.groupId]: {
          note: ""
        }
      }));
    });
  }, [assignedUserGroups]);

  const verticalListItems = useMemo(() => {
    const listItems = [];

    if (assignedUserGroups) {
      const assignedTabs = assignedUserGroups?.map((group) => ({
        label: (
          <VerticalListItem
            label={group.subcontractorCompany?.name ?? ""}
            subLabel={`${group.assigneeUser?.first_name} ${group.assigneeUser?.last_name}`}
            hasWarningIcon={group.materialsByStatus.WFStartedItems.length > 0}
          />
        ),
        key: group.groupId
      }));
      listItems.push(...assignedTabs);
    }

    if (unassignedUserGroups) {
      const unassignedTab = {
        label: (
          <VerticalListItem label="Unassigned Subcontractor" hasWarningIcon />
        ),
        key: unassignedUserGroups.groupId
      };
      listItems.push(unassignedTab);
    }

    if (inactiveUserGroups) {
      const inactiveTab = {
        label: (
          <VerticalListItem label="Inactive Subcontractor" hasWarningIcon />
        ),
        key: inactiveUserGroups.groupId
      };
      listItems.push(inactiveTab);
    }

    if (unregisteredUserGroups) {
      const unregisteredTab = {
        label: (
          <VerticalListItem label="Unregistered Subcontractor" hasWarningIcon />
        ),
        key: unregisteredUserGroups.groupId
      };
      listItems.push(unregisteredTab);
    }

    return listItems;
  }, [
    assignedUserGroups,
    unassignedUserGroups,
    unregisteredUserGroups,
    inactiveUserGroups
  ]);

  useEffect(() => {
    if (!selectedTabKey && verticalListItems.length > 0) {
      setSelectedTabKey(verticalListItems[0].key);
    }
  }, [selectedTabKey, verticalListItems]);

  const selectedGroup = useMemo(() => {
    return getGroupById(selectedTabKey, data?.groupedMaterials);
  }, [data?.groupedMaterials, selectedTabKey]);

  const onSend = async () => {
    const sendEstimateInviteData: {
      assignee: string;
      notes: string;
      material_ids: string[];
    }[] = [];
    assignedUserGroups?.forEach((group) => {
      const allowedMaterialIds =
        group.materialsByStatus.allowedToSendRequestItems.map(
          (material) => material.id
        );

      const requestAlreadySentMaterialIds =
        group.materialsByStatus.requestAlreadySentItems.map(
          (material) => material.id
        );

      if (
        allowedMaterialIds.length > 0 ||
        requestAlreadySentMaterialIds.length > 0
      ) {
        sendEstimateInviteData.push({
          assignee: group.groupId,
          notes: groupFormFields[group.groupId]?.note.trim(),
          material_ids: allowedMaterialIds.concat(requestAlreadySentMaterialIds)
        });
      }
    });

    // console.log("sendEstimateInviteData ", sendEstimateInviteData);

    setSubmitting(true);
    const inviteResponse = await sendFeatureWFInvite({
      variables: {
        sendEstimateInviteData
      }
    });

    if (inviteResponse.success) {
      message.success("Request sent successfully via email.");
      onComplete();
    }
    setSubmitting(false);
  };

  if (!data && loading) {
    return (
      <div
        className="h-full w-full flex items-center justify-center"
        data-testid="loading-spinner"
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className="request-durations-multi w-full h-full overflow-hidden flex flex-col">
      <div className="grow flex overflow-hidden">
        <div className="w-[30%] max-h-full overflow-y-auto border-0 border-r border-solid border-color-3">
          <VerticalList
            items={verticalListItems}
            onChange={setSelectedTabKey}
            activeKey={selectedTabKey}
          />
        </div>
        <div className="w-[70%] max-h-full overflow-y-auto p-4">
          {selectedGroup?.groupType === "unassigned" && (
            <ErrorBoundary>
              <NoAssigneeRhsContent
                key={selectedGroup.groupId}
                title="Materials without Subcontractor Assigned"
                group={selectedGroup}
              />
            </ErrorBoundary>
          )}
          {selectedGroup?.groupType === "assignee_unregistered" && (
            <ErrorBoundary>
              <NoAssigneeRhsContent
                key={selectedGroup.groupId}
                title="Materials with an Unregistered Subcontractor Assigned"
                group={selectedGroup}
              />
            </ErrorBoundary>
          )}
          {selectedGroup?.groupType === "inactive_user" && (
            <ErrorBoundary>
              <NoAssigneeRhsContent
                key={selectedGroup.groupId}
                title="Materials with an Inactive Subcontractor Assigned"
                group={selectedGroup}
              />
            </ErrorBoundary>
          )}
          {selectedGroup?.groupType === "assignee_actual" && (
            <ErrorBoundary>
              <HasAssigneeRhsContent
                key={selectedGroup.groupId}
                group={selectedGroup}
                formFieldsState={[groupFormFields, setGroupFormFields]}
              />
            </ErrorBoundary>
          )}
        </div>
      </div>
      <div className="border-0 border-t border-solid border-color-3">
        <Footer
          onCancel={onCancel}
          onSend={onSend}
          data={{
            selectedCount: selectedItems.length,
            excludedCount: excludedMaterials.length,
            emailsToBeSentCount: emailsToBeSentCount ?? 0
          }}
          submitting={submitting}
          sendRequestsBtnProps={{
            disabled: emailsToBeSentCount === 0
          }}
          sendRequestsBtnDisabledReason={
            emailsToBeSentCount === 0
              ? SendRequestsBtnDisabledReasons.NO_VALID_MATERIAL
              : null
          }
        />
      </div>
    </div>
  );
}

export default RequestDurationsMulti;
