import { FeatureTypes } from "entity-app/constants";
import { isNetworkAvailable } from "utils/utils";

export const FILESIZE = 100857000;

export const PartnerBaseRoute = "/partner";
export const MAX_NUMBER_OF_FETCH_ITEM = 8; // For fetching one-by-one submittal/material data for log page

export const colorCode = {
  white: "white",
  error: "#FF3535",
  success: "#65D154",
  grey: "#b1b1b1",
  process: "#00000033",
  lightGrey: "#dedede",
  fileName: "#CCCCCC",
  orange: "#FFA41B"
};

export const ErrorMessages = {
  RevisionMode: "Go to the latest revision to edit this information.",
  PermissionNotGranted: "You do not have permission to take this action.",
  EmailRequired: "Please enter a valid email ID",
  EmailInvalid: "Please enter a valid email ID",
  EmailAlreadyExist: "User already Exists",
  EmailProjectUserExist: "Project User Exists",
  PasswordEmptyCheck: "Please enter a valid password.",
  PasswordRequired:
    "Password should be of minimum 12 characters, contain at least 1 uppercase, 1 lower case and 1 special character.",
  ConfirmPasswordRequired: "Passwords do not match",
  AssigneeRole: "Please Select Assignee Role",
  AssigneeEmail: "Please enter an Assignee Email",
  AssigneeEmailRequired: "Please enter a valid email",
  AssigneeName: "Please enter an Assignee Name",
  FirstName: "Please enter first name",
  LastName: "Please enter last name",
  PhoneNumber: "Please enter a valid phone number",
  CompanyNameRequired: "Please enter the name of company",
  CompanySelectionRequired: "Please select a company",
  OrgRequired: "Please enter the name of organization",
  EmailSentMsg: "An email with a link to reset your password, has been sent",
  CreatedAccount: "Please check your email for a link to create your account",
  PasswordSetMsg: "Password has been successfully set.",
  CompanyNameExist: "Company already exists.",
  CompanyNameReqd: "Please enter the name of company.",
  Name: "Please enter name",
  ProjectRole: "Please assign project role to user.",
  SubscriptionRole: "Please assign role to user.",
  SubscriptionReqd: "Please enter subscription name.",
  CompanyUpdatedMsg: "Company details updated successfully",
  CompanyUpdateFailedMsg: "Unable to update company details.",
  SelectUserMsg: "Please select user",
  CalendarNameMsg: "Please enter calendar name",
  HolidayCountryNameMsg: "Please enter country name",
  HolidayDateMsg: "Please select holiday date",
  HolidayNameMsg: "Please enter holiday name",
  GroupNameMsg: "Please enter group name",
  ProjectNumberMsg: "Project number already exists",
  ProjectNameMsg: "Please enter project name",
  ProjectNameExistsMsg: "Project name already exists",
  ProjectDetailMsg: "Please enter project details",
  ProjectNumberErrMsg: "Please enter project number",
  SelectProjectForCopyMsg: "Please select a project to copy",
  EarlyStartMsg: "Click here to release the material ahead of schedule.",
  GroupUsersMsg: "Please select user",
  MaterialExists: "A material with this name already exists",
  CompanyProjectRole: "Please assign project role to the company",
  FieldDisabledDueToIntegrationMode:
    "Field cannot be edited as project is in Integration Mode",
  ActionDisabledDueToIntegrationMode:
    "Action cannot be performed as project is in Integration Mode",
  TimezoneFetchFailed: "Failed to fetch project timezone.",
  CompanyAlreadyLinkedToProject: "Company already linked to project",
  CompanyAddedSuccessMessage: "Company added successfully",
  CompanyAddedFailureMessage: "Company not added",
  CompanyInvitedSuccessMessage: "Company invited",
  CompanyInvitedFailureMessage: "Invite not sent",
  MaterialAccessDenied: "You do not have permission to view this material",
  SubmittalAccessDenied: "You do not have permission to view this submittal",
  SelectIntegrationType: "Please select integration type",
  FailedToGenerateACCAuthURL: "Could not generate Authorization URL",
  UnauthorizedPageAccess: "Sorry, you are not authorized to access this page.",
  NoLinksPresent: "No links present in the original.",
  reportExport: {
    errorMessage: "Risk report could not be downloaded due to some error.",
    submittalSuccessMessage:
      "Risk report is now available in your downloads folder."
  },
  comments: {
    fileSelectionLimitReached: "You can select upto $count$ attachments",
    addCommentFailed: "Failed to add comment",
    addAttachmentFailed: "Failed to upload attachment",
    fileDownloadStarted: "Please wait while the file is being downloaded"
  },
  specUploads: {
    pdfSelectionOnly: "Please select .PDF files only",
    specSectionCreationFailed: "Failed to create spec section",
    specSectionFileUploadFailed: "Failed to upload spec section",
    specFileUnderProcess:
      "File is being processed. You will be able to view the file once the process is completed."
  },
  sessionExpiredMsg:
    "Link expired. Check your inbox for a new invite email. If you are unable to find an email, please contact the Subscription Admin to resend the invite.",
  deleteMaterialFailed: "Failed to delete Materials.",
  cannotChangeMDBTemplateWFStarted:
    "Cannot change template as material workflow is already started.",
  failedToApplyMDBTemplate: "Failed to apply template.",
  SessionExpired: "Session expired, redirecting to login",

  SomethingWentWrong: "Sorry, something went wrong. Please try in sometime.",
  NetworkError: {
    offlineMessage:
      "You appear to be offline. Please confirm that you have an internet connection.",
    internetError:
      "The internet isn't working properly. Will you please try again?"
  },
  ServerError: {
    default: "Sorry, something went wrong. Please try in sometime.",
    "404": "Server url is not correct. Please contact administrator."
  },
  templateNameRequiredValidation: "Template Name is required.",
  templateNameExists: "Template name already exists.",
  templateAlreadyUsed:
    "This template is currently in use and cannot be edited. Please create a copy and make updates there.",
  disableTemplateFailed: "Failed to disable the template.",
  integration: {
    integrationFailed: "Failed to add integration."
  },
  uniquessViolation: "Uniqueness violation.",
  linkingErrorMsg: "Linking was unsuccessful, please try again.",
  unLinkingErrorMsg: "Unlinking was unsuccessful, please try again.",
  bidPackgeNumber: "Enter bid package number.",
  bidPackgeTitle: "Enter bid package title.",
  designPackgeNumber: "Enter design package number.",
  designPackgeTitle: "Enter design package title.",
  BidPackageTitleMsg: "Bid package title already exists.",
  DesignPackageTitleMsg: "Design package title already exists.",
  BidPackageNumberMsg: "Bid package number already exists.",
  DesignPackageNumberMsg: "Design package number already exists.",
  FieldNotFoundMessage: "Something went wrong. Try again later.",
  DefaultFailureMessage: "Something has failed. Try again later."
};

export const SuccessMessages = {
  CompanyLinkedToProject: "Company successfully linked to project",
  IntegrationSuccess: "Project integrated successfully",
  deleteMaterialSuccess: "Deleted successfully",
  DBTemplateAppliedSuccess: "Template applied successfully.",
  DBDefaultTemplateSet: "Default template set successfully.",
  DBTemplateCopiedSuccessfully: "Template copied successfully.",
  DBTemplateDisabledSuccessfully: "Template disabled successfully.",
  DBTemplateEnabledSuccessfully: "Template enabled successfully.",
  integration: {
    integrationAdded: "Integration added."
  }
};

export const InfoMessages = {
  EmptySubmittalWorkflowMode:
    "Import a log or click New submittal to create a log entry.",
  ImportingSubmittals: "Please wait while we are importing the submittals.",
  EmptyACCSubmittalLogs: 'Please click on "Import Log" to add entries',
  FailedImportMsg: "File import was failed.Try after sometime.",
  exportReportToPdfTooltip:
    "Exporting this report may take some time. You can navigate to any other page, we will inform once the report has been downloaded.",
  deleteMaterialConfirmTitle:
    "Are you sure you want to delete the selected material(s)?",
  enterTemplateName: "Enter Template name for the duplicate",
  defaultTemplateChangeCOnfirmation:
    "Are you sure you want to make this the default template?",
  confirmMsgForMaterialTrackingOff:
    "The selected submittal has material tracking turned off. Do you wish to turn on Track Material and proceed with the linking?",
  confirmDisableMDBTemplate: "Are you sure you want to disable this template?",
  confirmEnableMDBTemplate: "Are you sure you want to enable this template?"
};

export const submittalLogFilterTitles: any = {
  due_in_2_week: "Actions due in 2 weeks",
  high_risk_due_in_2_week: "High risk and due in 2 weeks",
  low_risk_due_in_2_week: "Medium/Low risk and due in 2 weeks",
  high_risk_due_later: "High risk and due later",
  low_risk_due_later: "Medium/Low risk and due later",
  alert_new_submittals: "New submittals synced today",
  no_plan: "Planning due",
  "due-in-days-for-sc": "At high risk"
};

export const materialLogFilterTitles: any = {
  due_in_2_week: "Actions due in 2 weeks",
  high_risk_due_in_2_week: "High risk and due in 2 weeks",
  low_risk_due_in_2_week: "Medium/Low risk and due in 2 weeks",
  high_risk_due_later: "High risk and due later",
  low_risk_due_later: "Medium/Low risk and due later",
  no_plan: "Planning due",
  "due-in-days-for-sc": "At high risk",
  is_linked_void_submittal: "Linked to voided submittals"
};

export const entityLogFilterTitles = (featureType: FeatureTypes): any => {
  switch (featureType) {
    case FeatureTypes.BID_PACKAGE:
      return {
        due_in_2_week: "Actions due in 2 weeks"
      };
      break;
    case FeatureTypes.DESIGN_PACKAGE:
      return {};
      break;
    default:
      return {};
  }
};

export const AttachmentConfirmationMessages: any = {
  heading: "Do you want to attach this document into this {type}?",
  subHeading:
    "This will give access to everyone in this {type} including external users outside of your org."
};
export const noDocumentMessage = "No attachments added.";
export const noDocumentMessageUpload = "Click Add to upload an attachment";
export const noDocumentSelectMessage =
  "Please select a document from the left panel to view document.";
export const discussionDetailsMessages = {
  message: "No discussion is selected.",
  descFirst: " Please select a discussion from the",
  boldTextFirst: " left",
  boldTextSecond: " panel",
  descSecond: ", to view/contribute to discussions."
};
export const discussionDocsMessages = {
  message: "No documents uploaded.",
  descFirst: "Please",
  boldText: "drag and drop",
  descSecond: "it to the button below",
  descThird: "to attach a file to this discussion."
};

export const steps = [
  {
    title: "Details",
    content: "Details"
  },
  {
    title: "Finish",
    content: "Finish"
  }
];
export const hexagoanStyleScreen1 = {
  textSize: "12px",
  className: "icon-style1",
  hexagoanSize: 100,
  errorStyleClass: "icon-style-wrong-screen-first",
  containerWidth: "200px"
};
export const hexagoanStyleScreen2 = {
  textSize: "8px",
  className: "icon-style2",
  hexagoanSize: 60,
  errorStyleClass: "icon-style-wrong-screen-second",
  containerWidth: "160px"
};
export const assigneesMessage = {
  project: "Not in Project",
  account: "Not in ConstructiveIQ",
  submittal: "Not in Submittal"
};

const CompanyOptions = [
  {
    name: "ABC Construction",
    email: "contact@abcconstruct.com",
    assignees: [
      {
        assignedTo: "Luke",
        destination: "Engineer",
        email: "luke@cconstruct.com",
        status: "Account Created in ConstructiveIQ",
        default: false
      },
      {
        assignedTo: "James",
        destination: "Project Manager",
        email: "james@cconstruct.com",
        status: "Project invite not accepted",
        default: false
      },
      {
        assignedTo: "Jack",
        destination: "Project Manager",
        email: "jack@cconstruct.com",
        status: "",
        default: false
      },
      {
        assignedTo: "John",
        destination: "Project Manager",
        email: "john@cconstruct.com",
        status: "Submittal invite not accepted",
        default: true
      }
    ]
  },
  {
    name: "A Construction",
    email: "contact@aconstruct.com",
    assignees: [
      {
        assignedTo: "John",
        destination: "Engineer",
        email: "john@cconstruct.com",
        status: "Project invite not accepted",
        default: false
      },
      {
        assignedTo: "Maria",
        destination: "Project Manager",
        email: "maria@cconstruct.com",
        status: "Submittal invite not accepted",
        default: true
      },
      {
        assignedTo: "Luke",
        destination: "Engineer",
        email: "luke@cconstruct.com",
        status: "",
        default: false
      },
      {
        assignedTo: "Mira",
        destination: "Engineer",
        email: "mira@cconstruct.com",
        status: "Account Created in ConstructiveIQ",
        default: false
      }
    ]
  },
  {
    name: "B Construction",
    email: "contact@bconstruct.com",
    assignees: [
      {
        assignedTo: "Lionel",
        destination: "Engineer",
        email: "lionel@cconstruct.com",
        status: "Submittal invite not accepted",
        default: false
      },
      {
        assignedTo: "Ronaldo",
        destination: "Project Manager",
        email: "ronaldo@cconstruct.com",
        status: "Project invite not accepted",
        default: false
      },
      {
        assignedTo: "Luke",
        destination: "Engineer",
        email: "luke@cconstruct.com",
        status: "Account Created in ConstructiveIQ",
        default: true
      },
      {
        assignedTo: "Jack",
        destination: "Engineer",
        email: "jack@cconstruct.com",
        status: "",
        default: false
      }
    ]
  },
  {
    name: "C Construction",
    email: "contact@cconstruct.com",
    assignees: [
      {
        assignedTo: "Mark",
        destination: "Engineer",
        email: "mark@cconstruct.com",
        status: "Account Created in ConstructiveIQ",
        default: false
      },
      {
        assignedTo: "Roger",
        destination: "Project Manager",
        email: "roger@cconstruct.com",
        status: "Submittal invite not accepted",
        default: true
      },
      {
        assignedTo: "Luke",
        destination: "Engineer",
        email: "luke@cconstruct.com",
        status: "Project invite not accepted",
        default: false
      },
      {
        assignedTo: "John",
        destination: "Engineer",
        email: "john@cconstruct.com",
        status: "",
        default: false
      }
    ]
  },
  {
    name: "Architect Company",
    email: "contact@architectconstruct.com",
    assignees: [
      {
        assignedTo: "Mark",
        destination: "Architect",
        email: "mark@cconstruct.com",
        status: "Submittal invite not accepted",
        default: false
      },
      {
        assignedTo: "Jack",
        destination: "Architect",
        email: "jack@cconstruct.com",
        status: "Account Created in ConstructiveIQ",
        default: false
      },
      {
        assignedTo: "Mack",
        destination: "Architect",
        email: "mack@cconstruct.com",
        status: "Project invite not accepted",
        default: true
      },
      {
        assignedTo: "Roger",
        destination: "Architect",
        email: "roger@cconstruct.com",
        status: "",
        default: false
      }
    ]
  }
];
export const imgExtentions = ["png", "jpg", "jpeg", "gif"];
const DependsOnOptions = ["All", "1079", "2098"];
const PastDueOptions = ["Past due date", "Due today", "Next 3 days", "Custom"];
const ProcureByOptions = ["Before", "After"];
const ProcureBySecondValues = ["This Week", "This Month"];
const StatusOptions = [
  "In Review",
  "Approved",
  "Approved with Comments",
  "Rejected",
  "For Approval"
];
const StatusOptionsForArchitects = [
  "Submittal required",
  "Submittal not required"
];
export const assigneesStatus = {
  account: "Account Created in ConstructiveIQ",
  project: "Project invite not accepted",
  submittal: "Submittal invite not accepted",
  newAssignee: "Project invite not accepted",
  present: "Present in ConstructiveIQ"
};
const AssigneeOptions = [
  {
    assignedTo: "Luke",
    destination: "Engineer",
    company: "ABC Construction"
  },
  {
    assignedTo: "James",
    destination: "Project Manager",
    company: "ABC Construction"
  },
  {
    assignedTo: "John",
    destination: "Project Manager",
    company: "A Construction"
  },
  {
    assignedTo: "Maria",
    destination: "Engineer",
    company: "A Construction"
  },
  {
    assignedTo: "Roger",
    destination: "Project Manager",
    company: "B Construction"
  },
  {
    assignedTo: "Mark",
    destination: "Engineer",
    company: "B Construction"
  },
  {
    assignedTo: "Lionel",
    destination: "Project Manager",
    company: "C Construction"
  },
  {
    assignedTo: "Ronaldo",
    destination: "Engineer",
    company: "C Construction"
  },
  {
    assignedTo: "Ronaldo",
    destination: "Architect",
    company: "Architect Company"
  },
  {
    assignedTo: "John",
    destination: "Architect",
    company: "Architect Company"
  }
];

const PackageOptions = ["920:Electrical", "Piping"];

// export const DATE_FORMAT_MMDDYYYY = "MM-DD-YYYY";

export const NewConversationContent = {
  General:
    "This will be a general discussion. Please select a Submittal prior to adding a new discussion if an association is desired",
  Submittal:
    "This discussion will be associated with the selected Submittal. Please unselect the Submittal, prior to adding a new discussion if an association is not desired."
};
const RoleOptions = [
  "Architect",
  "Project Engineer",
  "Project Manager",
  "Electrical Designer",
  "Mechanical Designer"
];

export const CopyDocumentModalContent = {
  Confirm: "Do you want to copy this document to ",
  General: "This will be associated with the selected Submittal."
};

export const CompanyChangeWarningMessages = {
  firstMsg:
    "Currently <b>{companyName}</b> has access to edit this submittal. To avoid conflicts, you cannot make edits while it is being worked on.",
  secondMsg: "Force regain edit access",
  thirdMsg: "<b>{companyName}</b> will lose edit access until you pass it back"
};

export const RegainEditConfirmationMessages = {
  firstMsg: "Force regain edit access?",
  secondMsg: "You are about to revoke edit access to the submittal.",
  thirdMsg:
    'This might lead to loss of unsaved work for the user; "<b>{currentUserEmail}</b>". Are you sure you want to continue?'
};

export const DialogTitles = {
  addCompany: "Add Company",
  addUser: "Add User",
  inviteUser: "Invite User"
};

export const DropDownData = {
  CompanyOptions,
  StatusOptions,
  StatusOptionsForArchitects,
  AssigneeOptions,
  DependsOnOptions,
  PastDueOptions,
  PackageOptions,
  ProcureByOptions,
  ProcureBySecondValues,
  RoleOptions,
  assigneesStatus
};

export enum AcceptanceStatus {
  INVITED = 1,
  ACTIVE = 2,
  DEACTIVATED = 3,
  NOT_INVITED = 4
}

export enum ESubmittalStatus {
  CREATE = 1,
  SUBMIT = 2,
  REVIEW = 3,
  DONE = 4,
  GC_REVIEW = 5,
  EXTERNAL_REVIEW = 6,
  READY_FOR_DISTRIBUTION = 7,
  VOID = 8
}

export enum EWorkflowStatusDataBlock {
  WF_NOT_STARTED = "WF_NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  WF_COMPLETED = "WF_COMPLETED"
}

export const SubmittalStatusToStrMap: any = {
  [ESubmittalStatus.CREATE]: "Pending SC Assignment",
  [ESubmittalStatus.SUBMIT]: "Pending SC Submission",
  [ESubmittalStatus.REVIEW]: "In Review",
  [ESubmittalStatus.DONE]: "Closed & Distributed",
  [ESubmittalStatus.GC_REVIEW]: "In GC Review",
  [ESubmittalStatus.EXTERNAL_REVIEW]: "In Design Team Review",
  [ESubmittalStatus.READY_FOR_DISTRIBUTION]: "In Final GC Review",
  [ESubmittalStatus.VOID]: "Void"
};

export const SubmittalWFStatusToStrMap: any = {
  [ESubmittalStatus.CREATE]: "Pending SC Assignment",
  [ESubmittalStatus.SUBMIT]: "SC Submittal Prep",
  [ESubmittalStatus.REVIEW]: "In Review",
  [ESubmittalStatus.GC_REVIEW]: "GC Review",
  [ESubmittalStatus.EXTERNAL_REVIEW]: "Design Review​",
  [ESubmittalStatus.READY_FOR_DISTRIBUTION]: "Final GC Review​",
  [ESubmittalStatus.VOID]: "Void​"
};

export const WeekdaysToNumberMap: any = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
};

export enum EUserTypes {
  GENERAL_CONTRACTOR = 1,
  SUBCONTRACTOR = 2,
  ARCHITECT = 3,
  ENGINEER = 4,
  OWNER = 5
}

export enum EFeaturePageId {
  SUBMITTAL = 1,
  MATERIAL = 2,
  BID_PACKAGE = 4,
  DESIGN_PACKAGE = 5
}

export const UserProjectRoles: any = {
  [EUserTypes.GENERAL_CONTRACTOR]: ["gc_project_admin", "gc_foreman"],
  [EUserTypes.SUBCONTRACTOR]: [
    "sc_project_admin",
    "sc_foreman",
    "gc_sc_project_admin",
    "gc_sc_foreman"
  ],
  [EUserTypes.ARCHITECT]: ["architect", "gc_sc_architect"],
  [EUserTypes.ENGINEER]: ["design_engineer", "gc_sc_design_engineer"],
  [EUserTypes.OWNER]: ["owner", "gc_sc_owner"]
};

export enum ECustomUserTypes {
  AllSCRoles = 1,
  AllGcEditorRoles = 2
}
export const CustomUserProjectRoles: any = {
  [ECustomUserTypes.AllSCRoles]: [
    "sc_project_admin",
    "sc_foreman",
    "gc_sc_project_admin",
    "gc_sc_foreman",
    "gc_sc_architect",
    "gc_sc_design_engineer",
    "gc_sc_owner"
  ],
  [ECustomUserTypes.AllGcEditorRoles]: [
    "gc_project_admin",
    "gc_sc_architect",
    "gc_sc_design_engineer",
    "gc_sc_owner",
    "gc_sc_project_admin"
  ]
};

export enum EUserRoleName {
  gc_project_admin,
  gc_foreman,
  sc_project_admin,
  sc_foreman,
  architect,
  design_engineer,
  owner,
  gc_sc_project_admin,
  gc_sc_foreman,
  gc_sc_architect,
  gc_sc_design_engineer,
  gc_sc_owner
}

export enum ProjectPermissionEnum {
  ViewProjectList,
  AddProjectUser,
  EditProjectDetail,
  CreateSubmittal,
  SubmittalWorkflow,
  InlineEditSubmittal,
  AddTradePartner,
  AddAttachment,
  AddEditCalendar,
  ViewLinkingPage,
  EditLinkingPage,
  LocationEdit,
  CreateDistributionGroup,
  EditDistributionGroup,
  CreateMaterial,
  InlineEditMaterialPrimaryFields,
  EditMaterialGeneralInfoAndParticipants,
  EditMaterialCharacteristics,
  ImportSchedule,
  MakeActiveSchedule,
  changeMaterialActualDate,
  changeMaterialPlannedDate,
  DeleteMaterialAttachment,
  EditMaterialDBOffset,
  ViewProjectReports,
  UploadSpecSectionFile,
  ViewDesignPage,
  CreateMaterialComment,
  EditDateBlockOffsetMaterial,
  EditDateBlockOffsetSubmittal,
  CreateMaterialCommentIfAssignee,
  ChangeMaterialTemplate,
  EditMaterialDBTemplate,
  BidPackageWrite,
  DesignPackageWrite,
  changeProjectedDate,
  RequestLeadTime,
  addProjectIntegration
}

export enum ScheduleDeltaCellRendererEnum {
  WithActualDate,
  WithoutActualDate,
  PlannedDate
}

export enum GoverningActivityEnum {
  Deleted_WasNotGoverningActivity,
  Deleted_WasSoleLinked,
  Deleted_WereLinkedOtherActivities,
  Changed_IsNotGoverningActivity,
  Changed_IsGoverningActivity,
  Changed_NeitherWasNorIs_GoverningActivity,
  Changed_WasNotButNowIs_GoverningActivity,
  Changed_WasAndNowIs_GoverningActivity
}

export enum ESubmittalMgmtModes {
  WORKFLOW_MODE = 1,
  INTEGRATION_MODE = 2
}

export const Regex = {
  phoneNumber: /^\+?\d+(?: \d+)*$/
};

export const MaterialNotReleaseStatus = "Not Started";

export const MaterialOffsetFieldsMappingOldToNew: any = {
  material_PO_fabrication_offset: "offset_1",
  fabrication_duration_offset: "offset_2",
  lead_time_offset: "offset_3",
  on_site_storage_offset: "offset_4"
};

export enum RiskStates {
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW"
}

export const ReportTitles = {
  submittalsRiskReport: "RISK REPORT",
  materialRiskReport: "RISK REPORT"
};

export const ConfigForMaterialCommentFiles = {
  allowedFileTypes: "*",
  maxSizeInMB: 5120
};

export enum ScheduleImpactStates {
  ACTIVITIES_UPDATED = "Activities Updated",
  ACTIVITIES_ADDED = "Activities Added",
  ACTIVITIES_DELETED = "Activities Deleted"
}

export enum IntegrationAuthProcessStatuses {
  PROCESSED = "PROCESSED",
  FAILED = "FAILED"
}

export const IntegrationSystemNameMap: any = {
  AUTODESK: "Autodesk Construction Cloud",
  PROCORE: "Procore",
  CMIC: "CMiC",
  EXTERNAL: "External",
  BIM360: "BIM360"
};

export const IntegrationAuthStates: any = {
  REAUTHORIZATION_REQUIRED: "REAUTHORIZATION_REQUIRED"
};

export enum AuditLogFeatures {
  SUBMITTAL_LOG = "SUBMITTAL_LOG",
  MATERIAL_LOG = "MATERIAL_LOG",
  SUBMITTAL_RISK_REPORT = "SUBMITTAL_RISK_REPORT",
  MATERIAL_RISK_REPORT = "MATERIAL_RISK_REPORT"
}

export enum AuditLogFeatureType {
  LOG_EXPORTED = "LOG_EXPORTED"
}

export enum EVENTS {
  SUBSCRIPTION_USER_LOGIN = "SUBSCRIPTION LOGIN",
  PROJECT_USER_LOGIN = "PROJECT LOGIN"
}

export enum EntityType {
  Submittal = "submittal",
  Material = "material",
  None = "not_defined"
}

export enum UserParticipationType {
  ASSIGNEE = 1,
  PRECONSTRUCTION_LEAD = 2,
  OPERATIONS_LEAD = 3,
  RESPONSIBLE_DESIGNER = 4,
  GC_REPRESENTATIVE = 5
}

export enum CompanyParticipationType {
  AWARDED_SUB_CONTRACTOR = 2,
  DESIGN_FIRM_CONTRACTOR = 3,
  BIDDING_SUB_CONTRACTOR = 4
}

export const SidepanelStyles: any = {
  style: {
    right: 0,
    bottom: 0,
    top: 40,
    padding: 0,
    position: "absolute"
  }
};

export const ErrorTypes = {
  netWorkError: (errorStr: string) => {
    return errorStr === "Failed to fetch" && !isNetworkAvailable();
  },
  fieldNotFound: (errorStr: string) => {
    const regex = /field '([^']+)' not found in type: '([^']+)'/;
    return errorStr.match(regex);
  }
};
export enum EmailInviteStatus {
  REQUEST_SENT = "REQUEST_SENT"
}

export enum SourceNames {
  CONSTRUCTIVIQ = "ConstructivIQ"
}

export enum IntegrationLinkTooltipMsg {
  AUTODESK = "Open in Autodesk Construction Cloud",
  PROCORE = "Open in PROCORE"
}

export enum FeatureTypesIndex {
  SUBMITTAL = 1,
  MATERIAL = 2,
  BID_PACKAGE = 4,
  DESIGN_PACKAGE = 5
}
