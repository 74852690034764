import { gql } from "@apollo/client";
import { message } from "antd";
import { noAuthClient } from "services/axios";
import { getAccessToken, getUser, logout } from "services/auth";
import { getGQLQueryData } from "entity-app/services";
import { CHECK_FEATURE_FIELD_VALUE_EXISTS } from "entity-app/graphQL/ciq-feature-queries";
import {
  CustomUserProjectRoles,
  ESubmittalMgmtModes,
  UserProjectRoles
} from "../constants";

export const compareObjectPropertyValues = (
  objectArr: any[],
  fieldsToCheck: any[]
): { diffFoundSummary: boolean; fieldsAsArray: any[]; fieldsAsObject: any } => {
  if (objectArr && objectArr.length === 1)
    return {
      diffFoundSummary: false,
      fieldsAsArray: fieldsToCheck,
      fieldsAsObject: {}
    };

  let diffFoundSummary = false;
  const fieldsAsObject: any = {};
  fieldsToCheck.forEach((item: any, idx: number) => {
    fieldsAsObject[item.field] = { ...item };
    objectArr.forEach((obj: any) => {
      if (objectArr[0][item.field] !== obj[item.field]) {
        // eslint-disable-next-line no-param-reassign
        fieldsToCheck[idx].diffFound = true;
        fieldsAsObject[item.field].diffFound = true;
        diffFoundSummary = true;
      } else if (!fieldsToCheck[idx].diffFound) {
        // eslint-disable-next-line no-param-reassign
        fieldsToCheck[idx].diffFound = false;
        fieldsAsObject[item.field].diffFound = false;
      }
    });
  });

  return { diffFoundSummary, fieldsAsArray: fieldsToCheck, fieldsAsObject };
};

export const downloadFileInBrowser = (url: string, fileName: string) => {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;
      // the filename you want
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
    })
    .catch(() => message.error("Downloading Failed"));
};

export const getUserCompany = (item: any) => {
  if (item?.company) return item?.company?.name;
  try {
    if (item?.subscription_vendor) return item.subscription_vendor.name;
    if (item?.subscription)
      return item?.subscription.organization_subscriptions?.[0]?.organization
        ?.name;
  } catch (ex) {
    return "";
  }
  return "";
};

export const getSubmittalStatus = (historyItems: any) => {
  if (!historyItems) return null;
  for (let h = 0; h < historyItems.length; h += 1) {
    if (historyItems[h].status) return historyItems[h].status;
  }
  return null;
};

export const matchUserProjectRole = (
  subscriptionRole: number,
  projectRole: string | undefined
) => {
  const roleList: any = UserProjectRoles[subscriptionRole];
  return roleList.includes(projectRole);
};

export const matchCustomUserProjectRole = (
  subscriptionRole: number,
  projectRole: string | undefined
) => {
  const roleList: any = CustomUserProjectRoles[subscriptionRole];
  return roleList.includes(projectRole);
};

export const padWithLeadingZeros = (num: number, totalLength: number) => {
  return String(num).padStart(totalLength, "0");
};

export const mergeArrays = (arrayA: any[], arrayB: any[], idField: string) => {
  const arrayAIds: string[] = [];
  const arrayBIds: string[] = [];

  for (let b = 0; b < arrayB.length; b += 1) {
    arrayBIds.push(arrayB[b][idField]);
  }

  const updatedArray: any = arrayA.map((objA: any) => {
    arrayAIds.push(objA[idField]);

    if (arrayBIds.includes(objA[idField])) {
      const objBIdx = arrayBIds.indexOf(objA[idField]);
      return { ...objA, ...arrayB[objBIdx] };
    }
    return objA;
  });

  for (let b = 0; b < arrayB.length; b += 1) {
    if (!arrayAIds.includes(arrayB[b][idField])) {
      updatedArray.push(arrayB[b]);
    }
  }
  // TODO: handle delete flags filtering later
  return updatedArray;
};

export const mergedStrings = (strArr: string[] = []) => {
  return strArr.filter((str) => str && str?.toString().length).join(" - ");
};

export const isProjectInIntegrationMode = (projectMode: number) => {
  if (!projectMode) return false;
  return projectMode === ESubmittalMgmtModes.INTEGRATION_MODE;
};

export const updateSubmittalParticipantsForINTG = (submittalDetails: any) => {
  if (!submittalDetails) return submittalDetails;

  const submittalParticipants = {
    assignee: "",
    submitter: "",
    submitter_user: null,
    gc_reviewer: "",
    gc_reviewer_user: {
      project_users: [
        {
          subscription: {
            organization_subscriptions: [
              {
                organization: {
                  name: ""
                }
              }
            ]
          }
        }
      ]
    },
    design_reviewer: "",
    design_reviewer_user: {
      project_users: [
        {
          subscription_vendor: {
            name: ""
          }
        }
      ]
    },
    responsible_contractor: "",
    responsible_contractor_vendor: {
      id: "",
      name: ""
    }
  };

  submittalDetails?.submittal_participation_from_integrations.forEach(
    (participant: any) => {
      const participantType: string =
        participant?.submittal_participation_type?.key;
      switch (participantType) {
        case "assignee":
          submittalParticipants.assignee =
            participant.project_integration_user_participant?.id;
          break;
        case "submitter":
          submittalParticipants.submitter =
            participant.project_integration_user_participant?.id;
          submittalParticipants.submitter_user = {
            ...participant.project_integration_user_participant,
            project_users: [
              {
                status_id: null
              }
            ]
          };
          break;
        case "gc_reviewer":
          submittalParticipants.gc_reviewer =
            participant.project_integration_user_participant?.id;
          submittalParticipants.gc_reviewer_user = {
            ...submittalParticipants.gc_reviewer_user,
            ...participant.project_integration_user_participant
          };
          submittalParticipants.gc_reviewer_user.project_users[0].subscription.organization_subscriptions[0].organization.name =
            participant.project_integration_user_participant?.project_integration_vendor?.name;
          break;
        case "design_reviewer":
          submittalParticipants.design_reviewer =
            participant.project_integration_user_participant?.id;
          submittalParticipants.design_reviewer_user = {
            ...submittalParticipants.design_reviewer_user,
            ...participant.project_integration_user_participant
          };
          submittalParticipants.design_reviewer_user.project_users[0].subscription_vendor.name =
            participant.project_integration_user_participant?.project_integration_vendor?.name;
          break;
        case "responsible_contractor":
          submittalParticipants.responsible_contractor =
            participant.project_integration_vendor_participant?.id;
          submittalParticipants.responsible_contractor_vendor = {
            ...submittalParticipants.responsible_contractor_vendor,
            ...participant.project_integration_vendor_participant
          };
          submittalParticipants.responsible_contractor_vendor.name =
            participant.project_integration_vendor_participant?.name;
          submittalParticipants.responsible_contractor_vendor.id =
            participant.project_integration_vendor_participant?.id;
          break;
        default:
        // console.log("participantType not matched: ", participantType);
      }
    }
  );
  const updatedSubmittalDetails = Object.assign(
    JSON.parse(JSON.stringify(submittalDetails)),
    submittalParticipants
  );
  return updatedSubmittalDetails;
};

export const getGQL = (query: string) => {
  return {
    query: gql`query ${query}`,
    sub: gql`subscription ${query}`
  };
};

export const downloadStringAsCSVFile = (data: any, fileName: string) => {
  const blob = new Blob([data], { type: "text/csv" });
  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.click();
  a.remove();
};

export const isProductionEnv = () => {
  return window.location.hostname === "app.constructiviq.com";
};

export const isProdDemoEnv = () => {
  return (
    window.location.hostname === "app.constructiviq.com" ||
    window.location.hostname === "demo.constructiviq.com"
  );
};

/**
 * This method will return the true if the code is running in development mode.
 * Below are the cases when we can say it is running on developer machine:
 *  - It is running on developer machin using `npm start` command.
 *  - It is running on developer machin after building the code.
 *  - Applocation can be loaded using localhost or using IP on the browser on developer machine
 *
 * @returns boolean
 */
export const isLocalhostDevEnv = () => {
  // if it is running from local machine then process will provide the Node Environment as production
  const isDevCodeRunning =
    process.env &&
    process.env.NODE_ENV &&
    process.env.NODE_ENV === "development"; // "production" for build code running after deploying to S3 for environments Dev, QA, Demo, Prod

  return isDevCodeRunning || window.location.hostname === "localhost";
};

/**
 * A simple method to replace the pattern with the values in the given template.
 * data is a hash/dictionary where keys are patterns and values will be used to
 * replace it in the provided template.
 *
 * Example:
 *  template = '/project/#{projectId}/reports/submittals/export?accessToken=#{accessToken}&subscriptionId=#{subscriptionId}'
 *  data = {
 *	 '#{accessToken}': 1234,
 *   '#{subscriptionId}': 'sub-123'
 *  };
 *
 * result : /project/#{projectId}/reports/submittals/export?accessToken=1234&subscriptionId=sub-123
 * @param template
 * @param data
 * @returns
 */
export const renderTemplate = (template: string, data: any) => {
  let renderedTemplate = template;
  // eslint-disable-next-line no-restricted-syntax
  for (const [pattern, value] of Object.entries(data)) {
    renderedTemplate = renderedTemplate.replaceAll(pattern, `${value}`);
  }
  return renderedTemplate;
};

/**
 * Method to get the web app url
 * @returns
 */
export const getTheAppUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ""
  }`;
};

export const getInitials = (fullName: string) => {
  const letterSplit = (fullName && fullName.split(" ")) || ["", ""];
  let letters = letterSplit.map((word: string) => word[0]).join("");
  if (letters.length > 3) {
    letters = letters.slice(0, 3);
  }
  return letters;
};

export const downloadFileFromS3andOpenInNewTab = async (
  url: string,
  type: string
) => {
  const downloadResp = await noAuthClient.get(url, {
    responseType: "blob"
  });
  if (downloadResp.data) {
    const file = new Blob([downloadResp.data], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
  return downloadResp;
};

export const getACCAuthErrorMessage = (errorObj: any) => {
  return errorObj.error_description || errorObj.error || "";
};

export const fileSelectValidator = (
  files: File[],
  config: { allowedFileTypes: string | string[]; maxSizeInMB: number }
) => {
  const { allowedFileTypes, maxSizeInMB } = config;

  const errorMessages: string[] = [];
  let allFilesPassed = true;

  const passedFiles: File[] = [];
  const failedFiles: { file: File; errorMessage: string }[] = [];

  const validateFile = (file: File) => {
    const fileType = file.name
      .substring(file.name.lastIndexOf("."))
      .toLowerCase();
    if (allowedFileTypes !== "*" && !allowedFileTypes.includes(fileType)) {
      return `File type ${fileType} is not allowed for ${file.name}`;
    }

    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > maxSizeInMB) {
      return `File ${file.name} exceeds the maximum size limit of ${maxSizeInMB}MB`;
    }
    return "";
  };

  files.forEach((file: File) => {
    const validationErrorMsg: string = validateFile(file);

    if (validationErrorMsg) {
      allFilesPassed = false;
      errorMessages.push(validationErrorMsg);
      failedFiles.push({
        file,
        errorMessage: validationErrorMsg
      });
    } else {
      passedFiles.push(file);
    }
  });

  const validationResponse = {
    allFilesPassed,
    errorMessages,
    passedFiles,
    failedFiles
  };

  return validationResponse;
};

export const subscriptionTokenExpiryInterceptor = (config: any) => {
  const token = getAccessToken();

  if (token !== null) {
    const expirationTime = (getUser() as any).exp * 1000 - 60000;
    if (Date.now() >= expirationTime) {
      logout();
    }
  }

  return config;
};

/**
 * Utility method to check if network is available or not.
 * Note: This utility will declair offline only if it is able to detect using
 * the browser native api otherwise it will return true. To make it better if
 * browser api is not there then we can use http call and track if network is
 * there or not but it is not inscope of this function.
 * @returns
 *
 * reference https://developer.mozilla.org/en-US/docs/Web/API/Navigator/onLine
 */
export const isNetworkAvailable = () => {
  return typeof navigator !== "undefined" &&
    typeof navigator.onLine === "boolean"
    ? navigator.onLine
    : true;
};

export const getProjectUserSources = (params: any) => {
  const userSourceInfos = params?.subscription_user?.user_source_infos;
  const allSources: string[] = [];
  if (userSourceInfos?.length) {
    userSourceInfos
      .map((usi: any) => usi.full_source_name)
      ?.reduce((acc: string[], item: string) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, allSources);
  }
  return allSources;
};

export const getProjectVendorSources = (data: any) => {
  const allSources: string[] = [];
  if (data?.subscription_vendor?.vendor_source_infos) {
    data?.subscription_vendor?.vendor_source_infos
      .map((vsi: any) => vsi.full_source_name)
      ?.reduce((acc: string[], item: string) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, allSources);
  }
  return allSources;
};

export const getSubscriptionUserSources = (params: any) => {
  const allSources: string[] = [];
  if (params?.user_source_infos) {
    params?.user_source_infos
      .map((usi: any) => usi.full_source_name)
      ?.reduce((acc: string[], item: string) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, allSources);
  }
  return allSources;
};

export const getSubscriptionVendorSources = (data: any) => {
  const allSources: string[] = [];
  if (data?.vendor_source_infos) {
    data?.vendor_source_infos
      .map((vsi: any) => vsi.full_source_name)
      ?.reduce((acc: string[], item: string) => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
        return acc;
      }, allSources);
  }
  return allSources;
};

export const updatePropertyAtPath = (
  obj: any,
  path: string,
  value: any
): any => {
  const keys = path.split(".");
  const lastKey = keys.pop()!;

  const newObj = structuredClone(obj);

  keys.reduce((acc, key) => {
    if (!acc[key]) {
      acc[key] = {};
    }
    return acc[key];
  }, newObj)[lastKey] = value;
  return newObj;
};

export const validatorForFieldValue = async (
  field: string,
  value: any,
  featureType: string,
  token: any,
  errorMsg: string
) => {
  const fieldMap: any = {};
  fieldMap[field] = { _eq: value };

  const where = {
    _and: [{ feature: { feature: { _eq: featureType } } }, fieldMap]
  };

  return new Promise((resolve, reject) => {
    const checkDuplicate = async () => {
      const response = await getGQLQueryData(
        token,
        CHECK_FEATURE_FIELD_VALUE_EXISTS,
        { where }
      );

      if (response?.data?.data?.feature_instance?.length > 0) {
        reject(errorMsg);
      }
      resolve(true);
    };
    checkDuplicate();
  });
};

export const getDaysText = (value: number | undefined | null) => {
  if (value === null || value === undefined) return "";

  if (value === 1) return "Day";
  return "Days";
};

export enum ImportStatuses {
  PROCESSING = "PROCESSING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  NA = "NA"
}

export type InviteAccUserAction = {
  isFromProjectUser: boolean;
  userData: any;
  setDrawerOpen: Function;
  showDrawerOpen: boolean;
  modelTitle: string;
};
