import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Dropdown, Menu, Modal, Tooltip, message } from "antd";
import "../submittal-list.css";
import { FilterChips } from "components";
import { ActionBar } from "components/navbar";
import SearchInput from "components/search-input";
import { FilterItem } from "models/types";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import {
  MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
  MUTATION_UPDATE_SUBMITTALS
} from "services/graphQL/mutations";
import { IntegrationProjectInfo } from "components/integration-project-info";
import ErrorBoundary from "components/error-boundary";
import ColumnConfigViews, {
  TSaveColumnState
} from "components/column-config-views";
import { DownOutlined } from "@ant-design/icons";
import { useCIQMutation } from "hooks/ciq-gql-hooks";
import {
  DATE_FORMAT_MMDDYYYY_HHMM_A_Z,
  INPUT_DATE_FORMAT
} from "utils/dateutils";
import moment from "moment";
import { useHistory } from "react-router";
import {
  AuditLogFeatureType,
  AuditLogFeatures,
  EFeaturePageId,
  ErrorMessages,
  ProjectPermissionEnum
} from "../../../constants";

export type FilterProps = {
  gridRef: any;
  items: FilterItem[];
  setItems: any;
  customDateFilter: any;
  setCustomDateFilter: any;
  onCreateLogClick: Function;
  onImportLogClick: Function;
  onAllSubmittalsClick: Function;
  showFiterChips: boolean;
  onSearch: any;
  stats: string;
  onExportCSV: Function;
  selectedRow: Array<any>;
  onSubmittalEditClick: (panel: "details" | "duration") => void;
  isIntegrationMode: boolean;
  displayRowCount: number;
  isIntegrationConfigured: boolean;
  resetFilters: Function;
  setFilterStateFromData: (columnState: TSaveColumnState) => void;
  afterApplyFilterCallBack?: Function;
  getColumnStateFromGrid: () => {
    columnList: any;
    quickSearch: string;
  } | null;
  isGridDataRendered: boolean;
  onSubmittalDeleted: (Ids: Array<string>) => void;
};

function SubmittalListFilterComponent(props: FilterProps) {
  const {
    gridRef,
    items,
    setItems,
    customDateFilter,
    setCustomDateFilter,
    onCreateLogClick,
    onImportLogClick,
    onAllSubmittalsClick,
    showFiterChips,
    onSearch,
    stats,
    onExportCSV,
    selectedRow,
    onSubmittalEditClick,
    isIntegrationMode,
    displayRowCount,
    isIntegrationConfigured,
    resetFilters,
    setFilterStateFromData,
    getColumnStateFromGrid,
    afterApplyFilterCallBack,
    isGridDataRendered,
    onSubmittalDeleted
  } = props;

  const selected = selectedRow ? selectedRow.length : 0;
  const history = useHistory();
  const {
    tokenContents,
    gqlClientForProject,
    projectIntegrationConfig,
    lastImportLogData,
    projectDetails,
    isFeatureFlagEnabled
  } = useContext(ProjectContext);

  const isBulkLinkingEnabled = isFeatureFlagEnabled(
    "MATERIAL_MULTIPLE_LINKING"
  );

  const [updateSubmittals] = useCIQMutation(MUTATION_UPDATE_SUBMITTALS, {
    client: gqlClientForProject
  });

  const [saveExportAuditLogEvent] = useCIQMutation(
    MUTATION_UPDATE_AUDIT_EXPORTED_LOGS,
    {
      client: gqlClientForProject
    }
  );

  const lastImportStatus = lastImportLogData?.status;

  const onFilterChipDelete = (item: FilterItem) => {
    if (item.field === customDateFilter.field) {
      const index = items.findIndex(
        (val) => val.field === customDateFilter.field
      );
      if (index > -1) {
        delete items[index];
      }
      setItems([...items].filter(Boolean));
      setCustomDateFilter({});
      gridRef.current.api.destroyFilter(customDateFilter.field);
    } else {
      const filterInstance = gridRef.current.api.getFilterInstance(item.field);
      if (filterInstance) {
        const model = filterInstance.getModel();
        const values =
          model?.values?.filter((val: any) => val !== item.value) || [];
        if (values.length === 0) {
          gridRef.current.api.destroyFilter(item.field);
          return;
        }
        filterInstance.setModel({ values });
        filterInstance.applyModel();
        gridRef.current.api.onFilterChanged();
      }
    }
  };

  const onCategoryDelete = (item: FilterItem) => {
    gridRef.current.api.destroyFilter(item.field);
  };

  const cannotCreateSubmittal = isPermissionNotGrantted(
    ProjectPermissionEnum.CreateSubmittal,
    tokenContents?.role!
  );

  const deleteSubmittals = () => {
    Modal.confirm({
      title: "Are you sure you want to delete the selected submittal(s)?",
      okText: "Yes",
      cancelText: "No",
      async onOk() {
        const deletedSubmittalsRes = await updateSubmittals({
          variables: {
            set: { deleted: true },
            where: {
              id: { _in: selectedRow.map((s) => s.id) }
            }
          }
        });
        if (deletedSubmittalsRes.data) {
          const deletedSubmittalIds =
            deletedSubmittalsRes.data.update_submittals.returning.map(
              (x: { id: string }) => x.id
            );
          onSubmittalDeleted(deletedSubmittalIds);
        }
        if (deletedSubmittalsRes.errors) {
          console.error(deletedSubmittalsRes.errors);
          message.error("Failed to delete Submittals.");
        }
      }
    });
  };

  const momentUpdateIntervalRef = useRef<any>(null);
  const [momentInstance, setMomentInstance] = useState<any>(null);

  const importEnableTime = useMemo(() => {
    if (!projectIntegrationConfig?.length) return null;
    const systemIntgConfig =
      projectIntegrationConfig?.length &&
      projectIntegrationConfig[0]?.systemIntgConfigProperties;
    return systemIntgConfig?.SYNC_INTERVAL;
  }, [projectIntegrationConfig]);

  const nextAllowedImportTime = moment(
    lastImportLogData?.lastSyncDateTime,
    INPUT_DATE_FORMAT
  ).add(importEnableTime, "minutes");

  const canImportNewFile = useMemo(() => {
    if (!lastImportLogData) return true;
    return momentInstance?.isSameOrAfter(nextAllowedImportTime);
  }, [momentInstance, nextAllowedImportTime, lastImportLogData]);

  useEffect(() => {
    momentUpdateIntervalRef.current = setInterval(() => {
      if (moment().isUtcOffset()) {
        setMomentInstance(moment());
        if (canImportNewFile) {
          clearInterval(momentUpdateIntervalRef.current);
          momentUpdateIntervalRef.current = null;
        }
      }
    }, 1000);
  }, [canImportNewFile]);

  const syncBtnVisible = useMemo(() => {
    if (!projectIntegrationConfig?.length) return false;
    const isSyncBtnVisible =
      projectIntegrationConfig?.length &&
      projectIntegrationConfig[0]?.systemIntgConfigProperties
        ?.INTEGRATION_METHOD === "api";
    return isSyncBtnVisible;
  }, [projectIntegrationConfig]);

  const isFileBasedIntegration = useMemo(() => {
    const systemIntgConfig =
      projectIntegrationConfig?.length &&
      projectIntegrationConfig[0]?.systemIntgConfigProperties;

    return isIntegrationMode && systemIntgConfig?.INTEGRATION_METHOD === "file";
  }, [isIntegrationMode, projectIntegrationConfig]);

  const disabledMessage = useMemo(() => {
    if (isIntegrationMode && syncBtnVisible)
      return ErrorMessages.ActionDisabledDueToIntegrationMode;

    if (cannotCreateSubmittal) return ErrorMessages.PermissionNotGranted;

    return "";
  }, [cannotCreateSubmittal, isIntegrationMode, syncBtnVisible]);

  const hoverMsgForFileBasedImportBtn = useMemo(() => {
    if (isFileBasedIntegration) {
      if (lastImportStatus && lastImportStatus === "PROCESSING") {
        return "Import is in Progress.";
      }

      if (!canImportNewFile) {
        return `You can only import again after ${nextAllowedImportTime.format(
          DATE_FORMAT_MMDDYYYY_HHMM_A_Z
        )}`;
      }
      return "Import Log";
    }
    if (cannotCreateSubmittal) return ErrorMessages.PermissionNotGranted;
    return "";
  }, [
    isFileBasedIntegration,
    cannotCreateSubmittal,
    lastImportStatus,
    canImportNewFile,
    nextAllowedImportTime
  ]);

  const bulkEditActionMenuItems = useMemo(() => {
    return (
      <Menu>
        {!isIntegrationMode && (
          <Menu.Item
            onClick={() => {
              onSubmittalEditClick("details");
            }}
            key={0}
          >
            Bulk Edit Details
          </Menu.Item>
        )}
        <Menu.Item
          onClick={() => {
            onSubmittalEditClick("duration");
          }}
          key={1}
          data-testid="bulk-edit-durations-button"
        >
          Bulk Edit Durations
        </Menu.Item>
        {isBulkLinkingEnabled && (
          <Menu.Item
            onClick={() => {
              history.push(
                `/project/${projectDetails?.id}/schedule/submittal-linking`,
                {
                  name: "Bulk Edit Linking",
                  selectedRows: selectedRow
                }
              );
            }}
          >
            Bulk Edit Links
          </Menu.Item>
        )}
      </Menu>
    );
  }, [
    history,
    isBulkLinkingEnabled,
    isIntegrationMode,
    onSubmittalEditClick,
    projectDetails?.id,
    selectedRow
  ]);

  const bulkEditButton = useMemo(() => {
    if (selected <= 1) return null;

    return (
      <Dropdown
        overlay={bulkEditActionMenuItems}
        disabled={cannotCreateSubmittal}
      >
        <Button
          disabled={cannotCreateSubmittal}
          title={
            cannotCreateSubmittal
              ? ErrorMessages.PermissionNotGranted
              : "Actions"
          }
          data-testid="bulk-edit-button"
        >
          {`Actions (${selected})`}
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }, [bulkEditActionMenuItems, cannotCreateSubmittal, selected]);

  const saveExportAuditLog = async () => {
    const payload: any = {
      variables: {
        eventType: AuditLogFeatureType.LOG_EXPORTED,
        feature: AuditLogFeatures.SUBMITTAL_LOG
      }
    };
    await saveExportAuditLogEvent(payload);
  };

  const disableAccImportLogBtn = useMemo(() => {
    return (
      cannotCreateSubmittal ||
      (lastImportStatus && lastImportStatus === "PROCESSING") ||
      !canImportNewFile
    );
  }, [canImportNewFile, cannotCreateSubmittal, lastImportStatus]);

  return (
    <ActionBar>
      <div className="flex items-center">
        <div
          style={{
            fontWeight: 600
          }}
          className="uppercase shrink-0"
        >
          {stats}&nbsp;&nbsp;
          {selected > 0 && `${selected} Selected`}{" "}
        </div>
        {isIntegrationMode && isIntegrationConfigured && (
          <ErrorBoundary>
            <IntegrationProjectInfo
              syncBtnVisible={syncBtnVisible}
              isFileBasedIntegration={isFileBasedIntegration}
            />
          </ErrorBoundary>
        )}
      </div>
      <div className="flex space-x-2 items-center">
        <div className="flex">
          <SearchInput
            placeholder="Search Submittals"
            onChange={onSearch}
            disabled={isGridDataRendered}
          />
        </div>
        <ColumnConfigViews
          setFilterStateFromData={setFilterStateFromData}
          getColumnStateFromGrid={getColumnStateFromGrid}
          afterApplyFilterCallBack={afterApplyFilterCallBack}
          featureId={EFeaturePageId.SUBMITTAL}
        />
        {showFiterChips && (
          <FilterChips
            items={items}
            onChipDelete={onFilterChipDelete}
            onCategoryDelete={onCategoryDelete}
            resetAll={resetFilters}
          />
        )}

        {!showFiterChips && (
          <Button onClick={() => onAllSubmittalsClick()}>All Submittals</Button>
        )}

        {bulkEditButton}

        {!isIntegrationMode && selected > 0 && (
          <Button
            onClick={deleteSubmittals}
            size="middle"
            className="bottomBarBtn"
            title={disabledMessage || "Delete"}
            disabled={isIntegrationMode || cannotCreateSubmittal}
            data-testid="delete-button"
          >
            Delete
          </Button>
        )}

        {!isIntegrationMode && (
          <>
            <Button
              title={disabledMessage || "New Submittal"}
              disabled={isIntegrationMode || cannotCreateSubmittal}
              onClick={() => {
                onCreateLogClick();
              }}
              data-testid="new-submittal-button"
            >
              New Submittal
            </Button>

            <Button
              title={disabledMessage || "Import Log"}
              disabled={isIntegrationMode || cannotCreateSubmittal}
              onClick={() => onImportLogClick()}
              data-testid="import-log-button"
            >
              Import Log
            </Button>
          </>
        )}

        {isIntegrationMode && isFileBasedIntegration && (
          <Tooltip title={hoverMsgForFileBasedImportBtn} color="#FFFFFFE0">
            <Button
              disabled={disableAccImportLogBtn}
              onClick={() => onImportLogClick()}
              data-testid="integration-import-log-button"
            >
              Import Log
            </Button>
          </Tooltip>
        )}

        <Button
          size="middle"
          title="Export Submittal Log"
          disabled={!displayRowCount}
          onClick={() => {
            onExportCSV();
            saveExportAuditLog();
          }}
          data-testid="export-button"
        >
          Export
        </Button>
      </div>
    </ActionBar>
  );
}

export default SubmittalListFilterComponent;
