import { Button, Form, FormRule, Input, Tabs, message } from "antd";
import "../module-settings.scss";

import { useContext, useEffect, useState } from "react";

import {
  ThresholdType,
  WorkflowDefaultData
} from "pages/project-setting-menu/module-settings/model";
import { useMutation } from "@apollo/client";
import { MUTATION_PROJECT_DEFAULT_CONFIG } from "services/graphQL/mutations";
import { ProjectContext } from "context/ProjectProvider";
import { useHistory, useParams } from "react-router";
import { DateBlockInfoAction } from "components/date-block/models";
import FeatureTemplateProjectSettings from "components/manage-feature-wf-templates/feature-template-project-settings";
import { FeatureTypes } from "entity-app/constants";
import RenameFieldSettings from "components/feature-rename-fields-settings";

const materialHeaderTitleMap: any = {
  material_id: "ID",
  name: "Material Title",
  description: "Material Description",
  status: "Status",
  next_planned_deadline: "Next Deadline",
  due_date: "Due Date",
  risk_level: "Risk",
  float: "Material Float",
  spec_section_no: "Spec Section",
  tag_name: "Material Tag",
  date_block_template_name: "Material Template",
  quantity: "Quantity",
  quantity_unit_id: "Quantity Unit",
  trade_partner: "Responsible Contractor",
  assignee: "Assignee",
  gc_representative: "GC Representative",
  manufacturer: "Manufacturer",
  linked_activities: "Linked Activities",
  linked_submittals: "Linked Submittals",
  material_size: "Material Size",
  size_unit_id: "Material Size Unit",
  part_number: "Part Number",
  serial_number: "Serial Number",
  next_action: "Next Action",
  warranty: "Warranty (In Years)",
  created_at: "Created At",
  wf_override_reason: "Released Early"
};

function MaterialSettings(props: {
  disabled: boolean;
  featureConfigurationsData: any;
  newProjectOffsetData: any;
  updateSettingsData: (data: any, tabTitle: string) => any;
  materialheader: any;
  materialDateBlockActions: Array<DateBlockInfoAction>;
}) {
  const {
    disabled,
    featureConfigurationsData,
    newProjectOffsetData,
    updateSettingsData,
    materialheader,
    materialDateBlockActions
  } = props;
  const [form] = Form.useForm();
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [showWarningMsg, setShowWarningMsg] = useState(false);
  const [projectOffset, setProjectOffset] = useState<any>();
  const [isFieldsEditable, setFieldsEditable] = useState<boolean>(false);
  const history = useHistory();
  const { projectId, tab3Id } = useParams() as any;
  const [selectedTab, setSelectedTab] = useState<string>(tab3Id || "templates");
  const { gqlClientForProject } = useContext(ProjectContext);

  const [materialOffsets, setMaterialOffsets] =
    useState<Array<WorkflowDefaultData>>();

  const [updateProjectConfigMutation] = useMutation<any>(
    MUTATION_PROJECT_DEFAULT_CONFIG,
    {
      client: gqlClientForProject
    }
  );

  // console.log("Material Headers..........:", materialheader);

  useEffect(() => {
    if (
      newProjectOffsetData &&
      newProjectOffsetData.workflow_defaults &&
      featureConfigurationsData &&
      featureConfigurationsData.project_feature_configurations
    ) {
      const defaultsMap: any = {};
      featureConfigurationsData.project_feature_configurations.forEach(
        (feature: any) => {
          if (feature?.feature_id === 2)
            defaultsMap.materialThreshold = feature;
        }
      );
      newProjectOffsetData.workflow_defaults?.forEach((wf_offset: any) => {
        // Material DB
        if (wf_offset.feature_id === 2) {
          const matOffsetsArr: Array<WorkflowDefaultData> =
            wf_offset.workflow_data ? [...wf_offset.workflow_data] : [];

          matOffsetsArr.push({
            offset_name: "mat_float",
            offset_value: wf_offset.float,
            milestone_name: "Material Float"
          });
          setMaterialOffsets(matOffsetsArr);
          matOffsetsArr.forEach((mat_offset: WorkflowDefaultData) => {
            const key = mat_offset.offset_name.replaceAll(" ", "_");
            defaultsMap[key] = mat_offset.offset_value;
          });
        }

        defaultsMap.field_mapping = materialheader?.field_mapping || [];
        defaultsMap.actions = materialDateBlockActions;
        form.setFieldsValue(defaultsMap);
        setProjectOffset(defaultsMap);
      });
    }
  }, [
    featureConfigurationsData,
    form,
    materialheader,
    newProjectOffsetData,
    materialDateBlockActions
  ]);

  const cancelAction = () => {
    form.resetFields();
    setFieldsEditable(false);
    setSubmitInProgress(false);
  };

  const updateDateBlockDuration = async (data: any) => {
    const materialOffsetsValue: any[] = [];

    materialOffsets?.forEach((mat_offset) => {
      const offset = mat_offset;
      offset.offset_value = Number(
        data[offset.offset_name.replaceAll(" ", "_")]
      );

      if (offset.offset_name !== "mat_float") {
        materialOffsetsValue.push(offset);
      }
    });

    const materialOffsetMap = {
      feature_id: 2,
      float: Number(data.mat_float),
      workflow_data: materialOffsetsValue
    };

    const updateResponse = await updateSettingsData(
      materialOffsetMap,
      "dateBlockDuration"
    );

    return updateResponse;
  };

  const updateRiskThreshold = (data: any) => {
    const updateResponse = updateSettingsData(
      {
        feature_id: 2,
        high_risk_threshold: data.materialThreshold.high_risk_threshold,
        low_risk_threshold: data.materialThreshold.low_risk_threshold
      },
      "riskThreshold"
    );

    return updateResponse;
  };

  const updateRenameFields = (data: any) => {
    const variables = {
      feature_id: materialheader?.feature_id,
      field_mapping: data.field_mapping,
      date_block_action: null
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const updateDateBlockActions = (data: any) => {
    const variables = {
      feature_id: materialheader?.feature_id,
      field_mapping: null,
      date_block_action: data.actions
    };

    const updateResponse = updateProjectConfigMutation({
      variables
    });

    return updateResponse;
  };

  const onFinishForm = async (data: any) => {
    let updateResponse: any;

    setSubmitInProgress(true);

    if (selectedTab === "dateblock") {
      updateResponse = await updateDateBlockDuration(data);
    }
    if (selectedTab === "rename-field") {
      updateResponse = await updateRenameFields(data);
    }

    if (selectedTab === "risk-threshold") {
      updateResponse = await updateRiskThreshold(data);
    }

    if (selectedTab === "actions") {
      updateResponse = await updateDateBlockActions(data);
    }

    if (!updateResponse) {
      setSubmitInProgress(false);
      return;
    }

    Promise.all([updateResponse]).then((res: [any]) => {
      const errorMes = res.find((r) => r.errors && r.errors[0]?.message);
      if (errorMes) {
        message.error(errorMes.errors[0]?.message);
      } else {
        message.success("Default Settings are updated successfully.");
      }
      setFieldsEditable(false);
      setSubmitInProgress(false);
    });
  };

  const formRule: FormRule[] = [
    {
      validator: async (_, value) => {
        const regex = /^[0-9\b]+$/;
        if (!regex.test(value)) {
          setShowWarningMsg(true);
          return Promise.reject(
            new Error("Please enter duration in numbers only.")
          );
        }
        setShowWarningMsg(false);
        return Promise.resolve();
      }
    }
  ];

  const thresholdRules: FormRule[] = [
    {
      required: true,
      message: "Please enter threshold"
    },
    {
      validator: (_, value) => {
        const number = Number(value);
        if (!Number.isInteger(number) || Number.isNaN(number)) {
          return Promise.reject(
            new Error("Please enter threshold in numbers ( + or - ) only.")
          );
        }
        return Promise.resolve();
      }
    },
    {
      warningOnly: true,
      message: (
        <div className="text-[#3B3B3BCC]">
          Please note that negative value of float indicates the workflow is
          already delayed.
        </div>
      ),
      validator: async (_, value) => {
        const number = Number(value);
        if (number < 0) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    },
    {
      validateTrigger: "onSubmit",
      message:
        "High risk threshold has to be lower than the low risk threshold.",
      validator: () => {
        const threshold: ThresholdType =
          form.getFieldValue("materialThreshold");
        if (
          Number(threshold.low_risk_threshold) <=
          Number(threshold.high_risk_threshold)
        ) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    }
  ];

  const renameFieldRules: FormRule[] = [
    {
      required: true,
      message: "Please enter field name",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter field name"));
        }
        return Promise.resolve();
      }
    }
  ];

  const milestoneActionsRules: FormRule[] = [
    {
      required: true,
      message: "Please enter milestone action",
      validator: async (_, value) => {
        if (value?.trim() === "") {
          return Promise.reject(new Error("Please enter milestone action"));
        }
        return Promise.resolve();
      }
    }
  ];

  const settingsFormRules: { [key: string]: FormRule[] } = {
    defaultoffset: formRule,
    thresholdRules,
    renameFieldRules,
    milestoneActionsRules
  };

  const headerView = (title: string) => {
    return (
      <div className="flex w-full items-center justify-between h-[40px] py-[2px] px-4">
        <div className=" text-[#3B3B3B] font-semibold text-sm">{title}</div>
        {isFieldsEditable ? (
          <div className="flex space-x-4">
            <Button
              className="w-24"
              type="primary"
              htmlType="submit"
              loading={submitInProgress}
              disabled={submitInProgress || showWarningMsg}
            >
              Save
            </Button>
            <Button
              className="w-24"
              disabled={submitInProgress || showWarningMsg}
              onClick={cancelAction}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            className="w-24"
            disabled={disabled}
            onClick={() => setFieldsEditable(true)}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  const riskThresholdInfo = (
    <div className="flex items-start w-full info-label pl-4">
      <div className="space-y-1">
        <div className="flex text-justify">
          Indicate the value of float at which you would consider the workflow
          to be at High, Medium and Low risk. These values can later be set for
          individual Material.
        </div>
        <ul className="list-disc space-y-2">
          <li>Material is at high risk if {"Float <="} High Risk Threshold</li>
          <li>
            Material is at medium risk if High Risk Threshold {"< Float <="} Low
            Risk Threshold
          </li>
          <li>Material is at low risk if {"Float >"} Low Risk Threshold</li>
        </ul>
      </div>
    </div>
  );

  const riskThreshold = (
    <div className="flex-col">
      {headerView("RISK THRESHOLD")}
      {riskThresholdInfo}
      <div className="flex justify-center w-full mt-10 bg-white">
        <div className="grid grid-cols-2 gap-x-20 w-3/5">
          <Form.Item
            label="HIGH RISK THRESHOLD"
            name={["materialThreshold", "high_risk_threshold"]}
            rules={settingsFormRules.thresholdRules}
          >
            <Input type="text" disabled={!isFieldsEditable} />
          </Form.Item>
          <Form.Item
            label="LOW RISK THRESHOLD"
            name={["materialThreshold", "low_risk_threshold"]}
            rules={settingsFormRules.thresholdRules}
          >
            <Input type="text" disabled={!isFieldsEditable} />
          </Form.Item>
        </div>
      </div>
    </div>
  );

  const tabItems = [
    {
      label: "Material Templates",
      key: "templates",
      children: (
        <FeatureTemplateProjectSettings featureId={FeatureTypes.MATERIALS} />
      )
    },
    {
      label: "Rename Fields",
      key: "rename-field",
      children: (
        <RenameFieldSettings
          isFieldsEditable={isFieldsEditable}
          submitInProgress={submitInProgress}
          showWarningMsg={showWarningMsg}
          cancelAction={cancelAction}
          disabled={disabled}
          setFieldsEditable={setFieldsEditable}
          projectOffset={projectOffset}
          header={materialheader}
          headerTitleMap={materialHeaderTitleMap}
          settingsFormRules={settingsFormRules}
          page="Material"
        />
      )
    },
    {
      label: "Risk Threshold",
      key: "risk-threshold",
      children: riskThreshold
    }
  ];

  return (
    <div className="w-full bg-white h-full module-settings--tabs">
      <Form
        layout="vertical"
        form={form}
        initialValues={projectOffset}
        onFinish={onFinishForm}
        className="flex flex-col"
        disabled={disabled}
        validateTrigger={["onSubmit", "onChange"]}
      >
        <Tabs
          tabPosition="left"
          tabBarGutter={2}
          tabBarStyle={{ width: "180px" }}
          activeKey={selectedTab}
          items={tabItems}
          onChange={(activeKey) => {
            history.push(
              `/project/${projectId}/settings/modules/materials/${activeKey.toString()}`
            );
            setSelectedTab(activeKey);
            cancelAction();
          }}
          destroyInactiveTabPane
        />
      </Form>
    </div>
  );
}

export default MaterialSettings;
