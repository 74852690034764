import { CheckOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import { DateUtils } from "utils/dateutils";

type IndicatorProps = {
  actualDate: string | null;
  projetedDate: string | null;
};

function ProgressIndicatorCircle(props: IndicatorProps) {
  const { actualDate, projetedDate } = props;

  const getIndicator = () => {
    if (!actualDate) return <div className="bl-circle" />;
    if (!projetedDate)
      return (
        <div className="bl-circle">
          <div className="w-full h-full text-xs rounded-[70%] flex items-center justify-center">
            <CheckOutlined />
          </div>
        </div>
      );

    const actualDays = DateUtils.dateTimeObj(actualDate).startOf("day");
    const projectedDays = DateUtils.dateTimeObj(projetedDate).startOf("day");

    const diffBetweenActualAndPlanned =
      actualDays.diff(projectedDays) / 86400000;
    if (diffBetweenActualAndPlanned <= 0) {
      return (
        <div className="bl-circle">
          <div className="date-block-on-track-circle w-full h-full text-xs rounded-[70%] flex items-center justify-center">
            <Tooltip title="On track">
              <CheckOutlined />
            </Tooltip>
          </div>
        </div>
      );
    }
    return (
      <div className="bl-circle">
        <div className="date-block-delayed-circle w-full h-full text-xs rounded-[70%] flex items-center justify-center">
          <Tooltip title="Delayed">
            <CheckOutlined />
          </Tooltip>
        </div>
      </div>
    );
  };
  return getIndicator();
}
export default ProgressIndicatorCircle;
