import { Button, Form, Input, Spin } from "antd";
import classNames from "classnames";

const fieldsToSkip: string[] = [];

const renameFieldsInfo = (page: string) => (
  <div className="flex items-center w-full info-label pl-4">
    <div className="flex text-justify">
      Rename the column headers of the {page} log
    </div>
  </div>
);

interface RenameFieldSettingsProps {
  isFieldsEditable: boolean;
  submitInProgress: boolean;
  showWarningMsg: boolean;
  cancelAction: () => any;
  disabled: boolean;
  setFieldsEditable: (value: boolean) => void;
  projectOffset: any;
  header: any;
  headerTitleMap: any;
  settingsFormRules: any;
  page: string;
}

function RenameFieldSettings({
  isFieldsEditable,
  submitInProgress,
  showWarningMsg,
  cancelAction,
  disabled,
  setFieldsEditable,
  projectOffset,
  header,
  headerTitleMap,
  settingsFormRules,
  page
}: RenameFieldSettingsProps) {
  return (
    <div className="flex-col">
      <div className="flex w-full items-center justify-between h-[40px] py-[2px] px-4">
        <div className=" text-[#3B3B3B] font-semibold text-sm">
          RENAME FIELDS
        </div>

        {isFieldsEditable ? (
          <div className="flex space-x-4">
            <Button
              className="w-24"
              type="primary"
              htmlType="submit"
              loading={submitInProgress}
              disabled={submitInProgress || showWarningMsg}
            >
              Save
            </Button>
            <Button
              className="w-24"
              disabled={submitInProgress || showWarningMsg}
              onClick={cancelAction}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            className="w-24"
            disabled={disabled}
            onClick={() => setFieldsEditable(true)}
          >
            Edit
          </Button>
        )}
      </div>
      {renameFieldsInfo(page)}
      <div className="flex justify-center w-full mt-10 overflow-y overflow-x-hidden max-h-[calc(100vh-250px)]">
        {projectOffset?.field_mapping ? (
          <div className="grid grid-cols-2 py-2 gap-x-10 w-4/5">
            <Form.List name="field_mapping">
              {(fields) => (
                <>
                  {fields.map((field, index) => {
                    const skipThisField = fieldsToSkip.includes(
                      projectOffset?.field_mapping[index].field_name
                    );

                    const titleKey = header?.field_mapping[index]?.field_name;

                    // CIQ-2391 Remove fields which we do not allow renaming of from the settings page
                    const fieldWrapperClasses = classNames(
                      "grid grid-cols-2 justify-between items-center",
                      {
                        hidden:
                          skipThisField ||
                          !header?.field_mapping[index]?.allow_rename ||
                          !headerTitleMap[titleKey]
                      }
                    );

                    return (
                      <div className={fieldWrapperClasses}>
                        <p className="col-span-1 titles mr-1">
                          {headerTitleMap[titleKey] || ""}
                        </p>
                        <Form.Item
                          {...field}
                          className="col-span-1"
                          key="caption_en"
                          name={[field.name, "caption_en"]}
                          rules={settingsFormRules.renameFieldRules}
                        >
                          <Input type="text" disabled={!isFieldsEditable} />
                        </Form.Item>
                      </div>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );
}

export default RenameFieldSettings;
