import {
  TEntityMilestone,
  TFeatureWorkflowInstance,
  TFetureDetailUpdateAction
} from "entity-app/models";
import { InfoCircleOutlined } from "@ant-design/icons";

import { DateUtils } from "utils/dateutils";
import { Moment } from "moment";
import { Button, Tooltip } from "antd";
import { useMemo, useRef } from "react";
import DatePickerWithIcon from "components/date-picker-with-icon/date-picker-with-icon";
import { getDaysText } from "utils/utils";
import SetActualDatePicker from "components/date-block/set-actual-date-button";
import { TWidthCalculation } from "../../models";

function MileStoneBlock(props: {
  milestone: TEntityMilestone;
  prevMilestone: TEntityMilestone | undefined;
  nextMilestone: TEntityMilestone | undefined;
  currentWorkflowMilestone: TEntityMilestone | undefined;
  widthCal: TWidthCalculation;
  actions: { updateFeatureWorkflowInstanceMilestone: Function };
  featureWorkflowInstance: TFeatureWorkflowInstance;
  disabled: boolean;
}) {
  const {
    milestone,
    widthCal,
    prevMilestone,
    nextMilestone,
    currentWorkflowMilestone,
    actions,
    disabled,
    featureWorkflowInstance
  } = props;

  const infoMilestoneAction = (
    <Tooltip title={milestone.milestone_action}>
      <InfoCircleOutlined className="pl-1" />
    </Tooltip>
  );

  const disabledActualDate = (current: Moment) => {
    const after = DateUtils.dateTimeObj(current).isAfter(
      DateUtils.dateTimeObj()
    );
    if (!prevMilestone) return after;

    const currDateFromPicker = DateUtils.dateTimeObj(current)
      .hours(13)
      .minutes(0)
      .seconds(0);
    const before = currDateFromPicker.isBefore(
      DateUtils.dateTimeObj(prevMilestone?.actual_date)
    );
    return before || after;
  };

  const childRefPlannedDate = useRef<any>();
  const renderExtraFooterDiv = (
    <div className="flex justify-center">
      <Button
        // disabled={cannotChangePlannedDate}
        onClick={() => {
          childRefPlannedDate.current?.close();
          actions.updateFeatureWorkflowInstanceMilestone({
            id: milestone.id,
            set: { actual_date: null }
          });
        }}
      >
        Clear Actual Date
      </Button>
    </div>
  );

  const onChangeActualDate = (date: Moment | null) => {
    if (date) {
      const saveDate = DateUtils.formatDateWithLunchTime(date);
      const set = { actual_date: saveDate };
      actions.updateFeatureWorkflowInstanceMilestone({
        id: milestone.id,
        set
      });
    }
  };

  const actualDatePicker = (
    <DatePickerWithIcon
      value={
        milestone.actual_date
          ? DateUtils.dateTimeObj(milestone.actual_date)
          : undefined
      }
      onChange={onChangeActualDate}
      ref={childRefPlannedDate}
      disabledDate={disabledActualDate}
      renderExtraFooter={() => {
        return milestone.actual_date ? renderExtraFooterDiv : undefined;
      }}
    />
  );

  const projectedDiv = !milestone.actual_date && milestone.projected_date && (
    <div className="flex-1 flex-col text-block-card space-y-1">
      <div className="font-semibold">Projected date: </div>
      <span
        className={
          featureWorkflowInstance.current_delay != null &&
          featureWorkflowInstance.current_delay > 0 &&
          currentWorkflowMilestone !== milestone
            ? "bg-delay pl-1 pr-2 py-[1px] rounded italic"
            : ""
        }
      >
        {DateUtils.format(milestone.projected_date)}
      </span>
    </div>
  );

  const plannedDiv = (
    <div className="flex-1 flex-col text-block-card space-y-1">
      <div className="font-semibold">Planned date: </div>
      <span>
        {milestone.planned_date
          ? DateUtils.format(milestone.planned_date)
          : "-"}
      </span>
    </div>
  );

  const canEditActualDate =
    currentWorkflowMilestone === milestone ||
    currentWorkflowMilestone === nextMilestone;

  const dash = milestone.projected_date ? "" : "-";

  const actualDiv =
    !milestone.actual_date && canEditActualDate && !disabled ? (
      <div className="absolute bottom-2 left-[13px]">
        <SetActualDatePicker
          onChange={onChangeActualDate}
          disabledDate={disabledActualDate}
        />
      </div>
    ) : (
      <div className="flex-1 flex-col text-block-card space-y-1">
        <div className="flex space-x-1 items-center">
          <div className="font-semibold">Actual date:</div>
          <div>{!disabled && canEditActualDate && actualDatePicker}</div>
        </div>

        <span>
          {milestone.actual_date
            ? DateUtils.format(milestone.actual_date)
            : dash}
        </span>
      </div>
    );

  const classNameBlock = useMemo(() => {
    const blockClass = "shrink-0 border border-solid text-xs h-48 relative";
    const headerClass = "card-header";
    const planProjectDate = milestone.projected_date || milestone.planned_date;
    if (currentWorkflowMilestone === milestone && planProjectDate) {
      const isPlannedDateIsDelay =
        DateUtils.dateTimeObj()
          .startOf("D")
          .diff(DateUtils.dateTimeObj(planProjectDate).startOf("D"), "d") > 0;
      return isPlannedDateIsDelay
        ? {
            blockClass: `${blockClass} next-milestone-delay-border`,
            headerClass: `${headerClass} next-milestone-delay-text`
          }
        : {
            blockClass: `${blockClass} next-milestone-on-track-border`,
            headerClass: `${headerClass} next-milestone-on-track-text`
          };
    }
    return { blockClass, headerClass };
  }, [currentWorkflowMilestone, milestone]);

  const currentDelayDiv = (
    <div className="h-6 w-full">
      {featureWorkflowInstance.current_delay != null &&
        featureWorkflowInstance.current_delay > 0 &&
        currentWorkflowMilestone === milestone && (
          <div className="bg-delay text-xs p-1 flex items-center rounded-b">
            <div className="grow">
              <div className="flex items-center justify-center lowercase">
                <span className="font-bold pr-1">
                  {featureWorkflowInstance.current_delay}{" "}
                  {getDaysText(featureWorkflowInstance.current_delay)}
                </span>
                <span>late</span>
              </div>
            </div>
            <Tooltip
              title={`As of today, ${DateUtils.format(
                featureWorkflowInstance.today_date
              )}.`}
              overlayInnerStyle={{ color: "#000000CC" }}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}
    </div>
  );

  return (
    <div style={{ width: widthCal.milestonesWidth }}>
      <div className={classNameBlock.blockClass}>
        <div className={classNameBlock.headerClass}>
          <Tooltip title={milestone.name}>
            <div className="ellipsis-clamp-2">{milestone.name}</div>
          </Tooltip>

          {currentWorkflowMilestone === milestone && infoMilestoneAction}
        </div>
        <div className="p-3">
          <div className="text-xs h-full space-y-3">
            {plannedDiv}
            {projectedDiv}
            {actualDiv}
          </div>
        </div>
      </div>
      {currentDelayDiv}
    </div>
  );
}

export default function TimelineMilestones(props: {
  milestones: Array<TEntityMilestone>;
  featureWorkflowInstance: TFeatureWorkflowInstance;
  widthCal: TWidthCalculation;
  actions: TFetureDetailUpdateAction;
  disabled: boolean;
}) {
  const { milestones, widthCal, actions, disabled, featureWorkflowInstance } =
    props;
  const { length } = milestones;

  const currentMilestone = milestones.find((m) => !m.actual_date);

  return (
    <div className="flex h-[200px] space-x-6">
      {milestones.map((m, index) => {
        const prev = index > 0 ? milestones[index - 1] : undefined;
        const next = index < length - 1 ? milestones[index + 1] : undefined;
        return (
          <MileStoneBlock
            disabled={disabled}
            actions={actions}
            milestone={m}
            widthCal={widthCal}
            prevMilestone={prev}
            nextMilestone={next}
            currentWorkflowMilestone={currentMilestone}
            featureWorkflowInstance={featureWorkflowInstance}
            key={`TimelineMilestone_${m.id}`}
          />
        );
      })}
    </div>
  );
}
