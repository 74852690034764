import {
  ColDef,
  ColumnApi,
  CsvExportParams,
  GetRowIdParams,
  GridApi
} from "ag-grid-community";

import {
  ProjectSettingType,
  isPermissionNotGrantted
} from "context/ProjectProvider";

import { AgGridReact } from "ag-grid-react";
import { DateUtils } from "utils/dateutils";
import { dateCellRenderer, userOrgCellRenderer } from "components/cell-renders";
import { TSaveColumnState } from "components/column-config-views";

import {
  ESubmittalStatus,
  ProjectPermissionEnum,
  SubmittalStatusToStrMap
} from "../../constants";

// After refetch the data ,It help to persist Scroll Bar
export const getRowId = (params: GetRowIdParams<{ id: string }>) =>
  params.data.id;

export const canChangeSubmittalDBFloat = (params: any) => {
  const { context, data } = params;
  const { statusesMap, tokenContents } = context;
  const floatChangePermitted = !isPermissionNotGrantted(
    ProjectPermissionEnum.InlineEditSubmittal,
    tokenContents?.role!
  );

  const status = SubmittalStatusToStrMap[params?.data?.workflow_status];
  const isVoidSub = status === SubmittalStatusToStrMap[ESubmittalStatus.VOID];

  const isSubmittalWorkflowStarted =
    statusesMap[data.workflow_status].name?.toLowerCase() !==
    SubmittalStatusToStrMap[ESubmittalStatus.CREATE].toLowerCase();
  return floatChangePermitted && !isSubmittalWorkflowStarted && !isVoidSub;
};

type TSubmittalExportCsv = {
  ref: AgGridReact;
  isIntegrationMode: boolean;
  projectDetails: ProjectSettingType;
  hiddenColumns: any[];
  milestonesColumns: {
    groupColDefMilestone: ColDef[];
  };
};

const getFormattedValues = (params: any, props: TSubmittalExportCsv) => {
  const { value, column, node, context } = params;
  const { projectDetails, isIntegrationMode } = props;

  switch (true) {
    case column.colId === "submittal_sequence_id":
      try {
        const { data } = node;
        return projectDetails?.spec_section && data.spec_no
          ? `${data.spec_no} - ${value}`
          : `${value}`;
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "title":
      try {
        return node.data.title || "";
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "type" || column.colId === "status":
      if (column.colId === "type" && isIntegrationMode) {
        return value;
      }
      try {
        const dataMap = column.colDef.cellRendererParams.dataMapVar;
        const fieldLabel = column.colDef.cellRendererParams.labelVar;
        return context[dataMap][value][fieldLabel];
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "responsible_contractor":
      try {
        const { data } = node;
        return data.responsible_contractor_name;
      } catch (ex) {
        return "";
      }
      break;
    case column.colDef.cellRenderer === dateCellRenderer:
      try {
        return value ? DateUtils.format(value) : "";
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "assignee" ||
      column.colId === "gc_reviewer" ||
      column.colId === "design_reviewer" ||
      column.colId === "submitter":
      try {
        const { data } = node;
        const userData = userOrgCellRenderer({
          data,
          userInput: column.userProvidedColDef.cellRendererParams.userInput
        });
        if (!userData.fullName || !userData.fullName.trim()) return "";
        return `${userData.fullName} (${userData.userOrg})`;
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "linked_tasks":
      try {
        const tasks = value || [];

        const formattedItems = tasks.map((item: any) => {
          const name = item.activity_id
            ? `${item.activity_id} - ${item.task_name}`
            : item.task_name;
          return name;
        });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "linked_materials":
      try {
        const { data } = node;
        const materials = value || [];

        if (materials.length === 1 && materials[0].implicit) {
          const submittalRefrenceId =
            projectDetails?.spec_section && data.spec_no
              ? `${data.spec_no} - ${data.submittal_sequence_id}`
              : data.submittal_sequence_id;

          return `Material for Submittal ${submittalRefrenceId}`;
        }

        const formattedItems = materials
          .filter((material: any) => !material.implict)
          .map((item: any) => {
            return item.name;
          });

        return formattedItems.join("\n");
      } catch (ex) {
        return "";
      }
      break;
    case column.colId === "next_planned_deadline":
      try {
        if (!params.value) return "";
        return params.value;
      } catch (ex) {
        return "";
      }
      break;
    default:
      return value ?? "";
  }
};

const getParams = (props: TSubmittalExportCsv): CsvExportParams => ({
  columnKeys: props.ref?.columnApi
    ?.getAllGridColumns()
    ?.filter((col) => {
      const colId = col.getColId();
      if (colId === "description" || colId === "next_action") {
        return true;
      }
      if (colId === "submittal_schedule_links") {
        return false;
      }
      if (
        props.milestonesColumns.groupColDefMilestone.some(
          (x) => x.colId === colId
        )
      ) {
        return false;
      }
      return col.isVisible() && !props.hiddenColumns?.includes(colId);
    })
    .map((col) => col.getColId()),
  processCellCallback(params: any): string {
    return getFormattedValues(params, props);
  },
  fileName: `Submittal log_${props.projectDetails?.name}.csv`
});

export const exportSubmittalLogAsCSV = (props: TSubmittalExportCsv) => {
  props.ref?.api?.exportDataAsCsv(getParams(props));
};

export const getSubmittalColumnStateFromGrid = ({
  columnApi,
  api,
  skippedColumns
}: {
  columnApi: ColumnApi;
  api: GridApi;
  skippedColumns: (string | undefined)[];
}) => {
  const colNameMap: any = {
    custom_spec_section: "spec_no",
    submittal_sequence_id: "submittal_id",
    linked_tasks: "linked_activities",
    status: "workflow_status"
  };

  // const gridRefObj = gridRef?.current;

  const colFilters: any = api.getFilterModel();

  const columnState = columnApi
    ?.getColumnState()
    ?.map((column: any, index: number) => {
      if (skippedColumns.includes(column.colId)) return null;

      const colKey = column?.colId || "";
      const range = { ...colFilters[colKey]?.range };

      if (range) {
        range.to = range.to ? `${range.to}` : null;
        range.from = range.from ? `${range.from}` : null;
      }

      const field = {
        name: colNameMap[colKey] || column.colId,
        filter: colFilters[colKey]?.values || null,
        sortOrder: column?.sort || null,
        range
      };
      return {
        order: index + 1,
        field,
        visibility: !column.hide
      };
    })
    .filter((c: any) => c);

  return {
    columnList: columnState,
    quickSearch: ""
  };
};

export const setFilterStateFromSubmittalData = (props: {
  columnApi: ColumnApi;
  api: GridApi;
  skippedColumns: (string | undefined)[];
  submittalColumns: TSaveColumnState;
}) => {
  const { submittalColumns, columnApi, api, skippedColumns } = props;

  if (!submittalColumns) return;

  const filterstate: any = {};

  if (submittalColumns.columnList?.length > 0) {
    const colNameMap: any = {
      spec_no: "custom_spec_section",
      submittal_id: "submittal_sequence_id",
      linked_activities: "linked_tasks",
      workflow_status: "status"
    };
    const columnState: any = columnApi
      ?.getColumnState()
      ?.map((column: any) => {
        if (skippedColumns.includes(column.colId)) return null;
        const colKey = column?.colId || "";
        const col1 = submittalColumns.columnList?.find(
          (cols: any) =>
            colNameMap[cols.field.name] === colKey || cols.field.name === colKey
        );

        const filter = col1?.field.filter;
        if (filter) {
          const range: any = { ...col1?.field?.range };
          if (range) {
            range.to = Number(range.to);
            range.from = Number(range.from);
          }
          filterstate[colKey] = {
            values: filter || null,
            filterType: "set",
            range
          };
        }

        const gridCol = { ...column };
        gridCol.hide = !col1?.visibility;
        gridCol.order = col1?.order;
        gridCol.sort = col1?.field.sortOrder;

        return gridCol;
      })
      .filter((c: any) => c);
    if (columnState) {
      const sortedArr = columnState?.sort(
        (a: any, b: any) => a.order - b.order
      );

      columnApi.applyColumnState({
        state: sortedArr,
        applyOrder: true
      });

      if (Object.keys(filterstate).length > 0) {
        api?.setFilterModel(filterstate);
      }
    }
  }
};
