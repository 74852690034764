import { AgGridReact } from "ag-grid-react";
import { Button, notification } from "antd";
import CiqAgSearch from "components/ciq-ag-search";
import CiqGridViewWrapper from "components/ciq-grid-view-wrapper";
import { useCallback, useContext, useRef, useState } from "react";
import { calculateLogPageStats } from "components/ciq-log-page-header";
import { newEntityNotificationMessage } from "entity-app/shared-components/new-entity-notification-message";
import { useParams } from "react-router";
import { FeatureTypes } from "entity-app/constants";
import ColumnConfigViews from "components/column-config-views";
import { EFeaturePageId, ProjectPermissionEnum } from "constants/index";
import { UtilColumnConfig } from "components/column-config-views/column-config-util";
import { exportEntityLogAsCSV } from "entity-app/utils/export-entity-log-as-csv";
import {
  isPermissionNotGrantted,
  ProjectContext
} from "context/ProjectProvider";
import { useFeatureLogInlineEdit } from "entity-app/hooks/feature-log-inline-edit";
import { getRowId } from "pages/submittals-log-page/utils";
import { useDesignPackageAgGrid } from "./hooks/design-package-ag-grid-data";
import { FilterChipComponent } from "../../entity-app/shared-components/log-render-components/filter-chip-component";

import type { FilterChipComponentRef } from "../../entity-app/shared-components/log-render-components/filter-chip-component";
import CreateDesignPackageComponent from "./components/create-design-package";
import { useDesignPackageData } from "./hooks/design-package-data";

const featureKey = "design-packages"; // URL

function DesignPackageLogPage() {
  const gridRef = useRef<AgGridReact<any>>(null);
  const {
    projectDetails,
    tokenContents,
    columnHeaders: { designPackageHeaderMap }
  } = useContext(ProjectContext);
  const cannotWriteAccessOfDesignPackage = isPermissionNotGrantted(
    ProjectPermissionEnum.DesignPackageWrite,
    tokenContents?.role!
  );

  const pageData = useDesignPackageData({ gridRef });
  const agGridData = useDesignPackageAgGrid({
    featureKey,
    milestonesColumns: pageData.milestonesColumns,
    designPackageHeader: designPackageHeaderMap,
    cannotWriteAccessOfDesignPackage
  });
  const { onCellEditRequest } = useFeatureLogInlineEdit({
    refetchListData: pageData.refetchDesignPackageList
  });

  const filterChipRef = useRef<FilterChipComponentRef>(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const { projectId } = useParams() as any;

  const [isFirstDataRendered, setIsFirstDataRendered] = useState(false);
  const [statsData, setStatsData] = useState<{
    displayCount: number;
    filterName: undefined | string;
  }>();

  const openNotification = (id: string, title: string) => {
    api.open(
      newEntityNotificationMessage({
        projectId,
        id,
        title,
        featureKey
      })
    );
  };

  const onFirstDataRendered = useCallback(() => {
    if (
      pageData.savedColumns?.design_package_column_config &&
      gridRef.current
    ) {
      UtilColumnConfig.setFilterStateFromData({
        columnStates: pageData.savedColumns?.design_package_column_config,
        gridRef: gridRef.current
      });
    }
    pageData.calculateMaxNumberOfMilestone();

    setTimeout(() => {
      // NOTE: To load initial data once in onFilterApplied method
      setIsFirstDataRendered(true);
    }, 2000);
  }, [pageData]);

  const headerItems = [
    <CiqAgSearch key="DesignPackageLog_search" gridRef={gridRef} />,
    <ColumnConfigViews
      key="DesignPackageLog_ColumnConfigViews"
      featureId={EFeaturePageId.DESIGN_PACKAGE}
      setFilterStateFromData={(columnStates) => {
        if (gridRef.current)
          UtilColumnConfig.setFilterStateFromData({
            columnStates,
            gridRef: gridRef.current
          });
      }}
      getColumnStateFromGrid={() => {
        if (gridRef.current) {
          return UtilColumnConfig.getColumnStateFromGrid({
            gridRef: gridRef.current
          });
        }
        return null;
      }}
      afterApplyFilterCallBack={pageData.calculateMaxNumberOfMilestone}
    />,
    <FilterChipComponent
      key="DesignPackageLog_FilterChip"
      columnDefs={agGridData.columnDefs}
      gridRef={gridRef}
      ref={filterChipRef}
    />,
    <Button
      key="DesignPackageLog_new"
      onClick={() => {
        setShowDrawer(!showDrawer);
      }}
      disabled={cannotWriteAccessOfDesignPackage}
    >
      New Design Package
    </Button>,
    <Button key="DesignPackageLog_importLog" disabled>
      Import Log
    </Button>,

    <Button
      key="DesignPackageLog_export"
      placeholder="Export Design Package Log"
      onClick={() => {
        if (projectDetails && gridRef.current)
          exportEntityLogAsCSV({
            entityName: "Design Package Log",
            projectDetails,
            gridRef: gridRef.current,
            includedColumns: ["description"],
            excludedColumns: ["_milestones", "_durations"]
          });
      }}
      disabled={!pageData.designPackageList?.design_package_list_view}
    >
      Export
    </Button>
  ];

  const onEditCell = useCallback(
    (params: any) => {
      gridRef?.current?.api?.setFocusedCell(params.rowIndex, params.colKey);
      gridRef?.current?.api?.startEditingCell({
        rowIndex: params.rowIndex,
        colKey: params.colKey
      });
    },
    [gridRef]
  );

  return (
    <>
      {contextHolder}
      <CiqGridViewWrapper
        headerInfo={{
          entityName: "DesignPackageLog",
          titleParam: {
            title: "Design Packages",
            totalCount:
              pageData.designPackageList?.design_package_list_view?.length,
            filterStats: statsData
          },
          gridRef,
          items: headerItems
        }}
        gridInfo={{
          gridRef,
          columnDefs: agGridData.columnDefs,
          rowData: pageData.designPackageList?.design_package_list_view,
          defaultColDef: agGridData.defaultColDef,
          rowHeight: 62,
          getRowId, // After refetch the rowdata ,It help to persist Scroll Bar
          readOnlyEdit: true,
          singleClickEdit: true,
          stopEditingWhenCellsLoseFocus: true,
          onCellEditRequest,
          onFirstDataRendered,
          onSortChanged: () => {
            if (isFirstDataRendered) pageData.onSaveColumnState();
          },
          onFilterChanged: () => {
            filterChipRef.current?.onFilterApplied();
            setStatsData(
              calculateLogPageStats({
                gridRef,
                featureTypeId: FeatureTypes.DESIGN_PACKAGE
              })
            );
            if (isFirstDataRendered) pageData.onSaveColumnState();
          },
          onColumnVisible: (e) => {
            if (e.columnApi && e.source === "toolPanelUi") {
              if (e.column) {
                agGridData.milestonesColumns.onColumnVisible(
                  e,
                  pageData.maxNumberOfMilestone
                );
              } else {
                agGridData.milestonesColumns.updateAllMileStoneInitialVisibility(
                  e.columnApi,
                  pageData.maxNumberOfMilestone
                );
              }
              pageData.onSaveColumnState();
            }
          },
          onDragStopped: (e) => {
            const classNames = [
              "ag-header-cell-resize",
              "ag-tool-panel-horizontal-resize"
            ];
            if (e.columnApi && !classNames.includes(e.target.className)) {
              pageData.onSaveColumnState();
            }
          },
          context: {
            onEditCell,
            isGridCellEditable: agGridData.isGridCellEditable,
            refetchLogData: pageData.refetchDesignPackageList,
            editPermission: !cannotWriteAccessOfDesignPackage
          }
        }}
      />
      <CreateDesignPackageComponent
        setDrawerOpen={setShowDrawer}
        showDrawerOpen={showDrawer}
        modelTitle="Create Design Package"
        onDesignPackageCreated={(title: string, id: string) => {
          setShowDrawer(false);
          openNotification(id, title);
          pageData.refetchDesignPackageList();
        }}
      />
    </>
  );
}

export default DesignPackageLogPage;
